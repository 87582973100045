import moment from 'moment';

const CARD_DATE_FORMAT = 'DD/MM/YY';
export function formatCardTime(startTime?: string, endTime?: string, tz?: string) {
	return [startTime, endTime]
		.filter(el => el)
		.map(time => (tz ? moment.tz(time, tz) : moment(time)).format(CARD_DATE_FORMAT))
		.join(' - ');
}

const TABLE_DATE_FORMAT = 'DD MMM, YY';
export function formatTableTime(startTime?: string, endTime?: string) {
	return [startTime, endTime].map(time => (time ? moment(time).format(TABLE_DATE_FORMAT) : 'N/A')).join(' - ');
}
