import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Modal, Tooltip } from '@bringg/react-components';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './optimization-error-modal.scss';

export interface ModalParams {
	onProceedToOptimization?: () => void;
	errors: { [errorName: string]: Set<number | string> };
	canProceedToOptimization?: boolean;
	type: 'team' | 'tasks';
}
export interface Props {
	onClose: (isProceed?: boolean) => void;
	modalParams: ModalParams;
}

const OptimizationErrorModal = (props: Props) => {
	const { onClose, modalParams } = props;
	const { onProceedToOptimization, errors, canProceedToOptimization, type } = modalParams;

	const { t } = useTranslation();

	const onClickProceedToOptimization = () => {
		onProceedToOptimization();
		onClose(true);
	};

	const title = type === 'tasks' ? 'MODAL_TITLE_TASKS_ERROR' : 'MODAL_TITLE_OPT_TYPE_ERROR';
	const isShowProceedButton = !!onProceedToOptimization;

	const getModalFooter = () => (
		<div className="optimization-error-modal-footer">
			<Button onClick={() => onClose()} type={isShowProceedButton ? 'link' : 'primary'}>
				{t('OPTIMIZATION_ERROR_MODAL.CANCEL')}
			</Button>

			{isShowProceedButton && (
				<Tooltip title={canProceedToOptimization ? '' : t('OPTIMIZATION_ERROR_MODAL.NO_VALID_TASKS')}>
					<div>
						<Button
							type="primary"
							onClick={onClickProceedToOptimization}
							disabled={!canProceedToOptimization}
							className="proceed-button"
						>
							{t('OPTIMIZATION_ERROR_MODAL.PROCEED_TO_OPTIMIZATION')}
						</Button>
					</div>
				</Tooltip>
			)}
		</div>
	);

	const getErrorElementList = errorElements => {
		return (
			<ul className="task-error-list">
				{Array.from(errorElements).map(identifier => (
					<li className="task-link" key={`task-${identifier}`}>
						{type === 'tasks' ? (
							<>
								<a href={`#/history/${identifier}/`} target="_blank">
									{identifier}
									<BringgIcon iconName={BringgFontIcons.ExternalLink} className="external-icon" />
								</a>
							</>
						) : (
							identifier
						)}
					</li>
				))}
			</ul>
		);
	};

	return (
		<Modal
			visible
			title={<span className="error-modal-title">{t(`OPTIMIZATION_ERROR_MODAL.${title}`)}</span>}
			className="optimization-error-modal"
			onCancel={() => onClose()}
			footer={getModalFooter()}
		>
			<div className="modal-body">
				{Object.entries(errors).map(([error, errorElements]) => (
					<div key={error} className={classNames('single-error')}>
						{t(`OPTIMIZATION_ERROR_MODAL.${error}`)}
						{errorElements.size ? getErrorElementList(errorElements) : null}
					</div>
				))}
				{isShowProceedButton && (
					<div className="proceed_text">{t('OPTIMIZATION_ERROR_MODAL.PROCEED_WILL_EXCLUDE_THE_ORDER')}</div>
				)}
			</div>
		</Modal>
	);
};

export default OptimizationErrorModal;
