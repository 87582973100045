import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	dropHere: 'FILE_UPLOADER.DROP_HERE',
	browseFiles: 'FILE_UPLOADER.BROWSE_FILES'
};

export function useDraggerTranslation() {
	return useTranslationKeys<typeof translationKeys>(translationKeys);
}

export { translationKeys as bringgDraggerT };
