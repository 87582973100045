import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { getRoot } from 'mobx-easy';
import { Modal, Notification, Radio } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { RootStore } from '@bringg-frontend/bringg-web-legacy';

import { Translate } from '../../../translation';
import { PartialRunsAction, PartialRunsSource } from './partial-runs-consts';

import './partial-runs-actions-modal.scss';
export interface Props {
	onResponse: (tasksIds: number[]) => void;
	partialRunsSource: PartialRunsSource;
	onClose: () => void;
	selectedTasksIds: number[];
	partialRunIds: number[];
}

const PartialRunsActionsModal: React.FC<Props> = ({
	onResponse,
	partialRunsSource,
	onClose,
	selectedTasksIds,
	partialRunIds
}: Props) => {
	const { t } = useTranslation();
	const [selectedAction, setSelectedAction] = useState<PartialRunsAction>(PartialRunsAction.SELECT_ALL_IN_RUN);

	const textsBySource = {
		[PartialRunsSource.MOVE_FROM_DISPATCH_TO_PLANNING]: {
			title: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_TITLE',
			selectAll: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_SELECT_ALL_IN_RUN',
			removeSelectedFromRun: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_REMOVE_SELECTED_FROM_RUN'
		},
		[PartialRunsSource.MOVE_FROM_PLANNING_TO_DISPATCH]: {
			title: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_TITLE',
			selectAll: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_SELECT_ALL_IN_RUN',
			removeSelectedFromRun: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_REMOVE_SELECTED_FROM_RUN'
		}
	};

	const getRunsTaskIds = (taskIds: number[]): number[] => {
		const runIds = new Set<number>();
		taskIds.forEach(taskId => {
			const task = getRoot<RootStore>().data.tasksStore.get(taskId);
			task.run_id && runIds.add(task.run_id);
		});

		const tasksIdsForActions = [];
		runIds.forEach(runId => {
			const run = getRoot<RootStore>().data.runStore.get(runId);
			tasksIdsForActions.push(...run.task_ids);
		});
		return tasksIdsForActions;
	};

	const onApply = async () => {
		try {
			let tasksIdsForActions = selectedTasksIds;
			if (selectedAction === PartialRunsAction.REMOVE_SELECTED_FROM_RUN) {
				const promises = selectedTasksIds.map(taskId =>
					getRoot<RootStore>().data.tasksStore.update(taskId, { run_id: null, id: taskId })
				);
				await Promise.all(promises);
			} else {
				tasksIdsForActions = getRunsTaskIds(selectedTasksIds);
			}

			onResponse(tasksIdsForActions);
			onClose();
		} catch (e) {
			Notification.error('error!');
		}
	};

	const getRouteName = (routeId: number) => {
		const route = getRoot<RootStore>().data.runStore.get(routeId);
		const routeTitle = route.route_title ? `${route.route_title}_` : '';
		return `${routeTitle}${route.id}`;
	};

	return (
		<Modal
			visible
			title={t(textsBySource[partialRunsSource].title)}
			className="partial-runs-actions-modal"
			okText={t('RUN_PARTIAL_ACTIONS.OK')}
			cancelText={t('RUN_PARTIAL_ACTIONS.CANCEL')}
			onOk={onApply}
			onCancel={onClose}
		>
			<div>{t('RUN_PARTIAL_ACTIONS.PARTIALS_ORDERS_SELECTED')}:</div>
			<ul className="runs-list">
				{partialRunIds.map(routeId => (
					<li key={routeId}>
						<a href={`#/runs/${routeId}`} target="_blank" rel="noreferrer">
							<span className="run-link">
								{getRouteName(routeId)}
								<BringgIcon iconName={BringgFontIcons.OpenInNew} />
							</span>
						</a>
					</li>
				))}
			</ul>
			<div>{t('RUN_PARTIAL_ACTIONS.SELECT_WHAT_TO_DO')}:</div>

			<Radio.Group
				className="run-actions"
				value={selectedAction}
				onChange={e => setSelectedAction(e.target.value)}
			>
				<Radio value={PartialRunsAction.SELECT_ALL_IN_RUN}>
					<Translate text={'RUN_PARTIAL_ACTIONS.ENTIRE_ROUTE'} />
				</Radio>
				<Radio value={PartialRunsAction.REMOVE_SELECTED_FROM_RUN}>
					<Translate text={'RUN_PARTIAL_ACTIONS.ONLY_ORDERS'} />
				</Radio>
			</Radio.Group>

			{selectedAction === PartialRunsAction.REMOVE_SELECTED_FROM_RUN && (
				<div className="selection-comment">*{t('RUN_PARTIAL_ACTIONS.ONLY_SELECTED_WILL_BE_MOVED')}</div>
			)}
		</Modal>
	);
};

export default PartialRunsActionsModal;
