import React from 'react';

import { observer } from 'mobx-react';
import {
	HourFormat,
	ManualTriggerInputParam,
	PrimitiveManualTriggerParamDataType,
	ReferredManualTriggerParamDataType
} from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { configurationContentProvider } from '@bringg-frontend/bringg-web-legacy/automation-platform';
import { DynamicFormModal, DynamicFormStringType, FormFields } from '@bringg-frontend/dynamic-form-modal';
import { UserSelectorMode } from '@bringg/react-components';
import { getHourFormat, getTimeFormat, useDateTimeFormat } from '@bringg-frontend/hooks';

import { useStores } from 'bringg-web';

interface ManualTriggerDynamicFormModalProps {
	onClose: () => void;
	onSubmit: (values?: any) => void;
	name: string;
	vars?: ManualTriggerInputParam[];
}

const ManualTriggerFormModal = ({ name, vars, onClose, onSubmit }: ManualTriggerDynamicFormModalProps) => {
	const { t } = useTranslation();
	const dateTimeFormat = useDateTimeFormat();
	const customAttributes = useStores().customAttributesStore.activeCustomAttributesSortedByName;
	const customAttributeValuesByNamesMapper = [];

	const formFields: FormFields[] = (vars || []).map(field => {
		const control = configurationContentProvider.getFieldControl(field.data_type);

		const formField: FormFields = {
			key: field.name,
			label: field.name,
			control,
			mandatory: field.data_type !== PrimitiveManualTriggerParamDataType.BOOLEAN
		};

		switch (field.data_type) {
			case PrimitiveManualTriggerParamDataType.BOOLEAN:
				formField.valuePropName = 'checked';
				break;
			case PrimitiveManualTriggerParamDataType.NUMBER:
				formField.type = DynamicFormStringType.number;
				break;
			case PrimitiveManualTriggerParamDataType.STRING:
				formField.type = DynamicFormStringType.string;
				break;
			case PrimitiveManualTriggerParamDataType.DRIVER:
				formField.mode = UserSelectorMode.single;
				break;
			case PrimitiveManualTriggerParamDataType.TIME:
				formField.timeFormat = getTimeFormat();
				formField.use12Hours = getHourFormat() === HourFormat.TwelveHours;
				break;
			case PrimitiveManualTriggerParamDataType.DATETIME:
				formField.use12Hours = getHourFormat() === HourFormat.TwelveHours;
				formField.timeFormat = dateTimeFormat;
				break;
			case ReferredManualTriggerParamDataType.CUSTOM_ATTRIBUTE: {
				const enumDefinitions = customAttributes?.find(ca => ca.id === field.ref_id)?.enum_definition;
				formField.list_values = enumDefinitions?.map(keyVal => keyVal.name);
				customAttributeValuesByNamesMapper.push(fields => ({
					...fields,
					[field.name]: enumDefinitions.find(def => def.name === fields[field.name]).value
				}));
				break;
			}
		}

		return formField;
	});

	return (
		<DynamicFormModal
			title={name}
			okText={t('AUTOMATION.TRIGGER.MANUAL.MODAL.RUN')}
			cancelText={t('GLOBAL.CANCEL')}
			onClose={onClose}
			onSubmit={values => {
				onSubmit(customAttributeValuesByNamesMapper.reduce((acc, fn) => fn(acc), values));
			}}
			destroyOnClose
			visible
			formFields={formFields}
			okButtonProps={{ 'data-test-id': 'dynamic-submit-button' }}
		/>
	);
};

export default observer(ManualTriggerFormModal);
