import qs from 'qs';
import type { History, Location, LocationDescriptor } from 'history';

import { ObjectKey, UpdateURLStrategy } from './types';

const qsOptions: qs.IStringifyOptions<qs.BooleanOptional> = {
	encode: true,
	indices: false,
	format: 'RFC1738'
};

function parseQueryParams(searchQuery: string) {
	return qs.parse(searchQuery.startsWith('?') ? searchQuery.substring(1) : searchQuery);
}

function getValueOrNumberIfNumber<T>(value: T): T | number {
	if (typeof value !== 'string') {
		return value;
	}
	// If only number
	if (/^[0-9]+$/.test(value)) {
		return parseInt(value, 10);
	}

	return value;
}

export function searchParamsToObject(searchQuery: string, keysToTrack?: ObjectKey[]) {
	const filterObject = qs.parse(searchQuery.startsWith('?') ? searchQuery.substring(1) : searchQuery);

	return (keysToTrack || Object.keys(filterObject)).reduce((obj, key) => {
		if (!(key in filterObject)) {
			return obj;
		}
		const value = filterObject[key];
		if (typeof value === 'string') {
			obj[key] = getValueOrNumberIfNumber(value);
		} else if (Array.isArray(value)) {
			obj[key] = value.map(getValueOrNumberIfNumber);
		} else {
			obj[key] = value;
		}

		return obj;
	}, {});
}

export function isFilterObjectAndSearchParamsEquals<T>(
	cleanedFilterObject: T,
	search: string,
	keysToCmp?: (keyof T)[]
) {
	const currentSearchFilterObject = cleanFilterObject(
		qs.parse(search.startsWith('?') ? search.substring(1) : search)
	);
	if (keysToCmp === undefined) {
		return qs.stringify(cleanedFilterObject || {}) === qs.stringify(currentSearchFilterObject);
	}

	const filterObjectAfterUrlTransform = qs.parse(qs.stringify(cleanedFilterObject || {}, qsOptions));

	return keysToCmp.every(key => {
		const filterObjValue = filterObjectAfterUrlTransform[key as string];
		const valueFromUrl = currentSearchFilterObject[key as string];

		if (Array.isArray(filterObjValue) && Array.isArray(valueFromUrl)) {
			return (
				filterObjValue.length === valueFromUrl.length &&
				filterObjValue.every((item, i) => item === valueFromUrl[i])
			);
		}

		return filterObjValue === valueFromUrl;
	});
}

function filterNilOrEmpty(value): boolean {
	return value != null && value !== '' && !(Array.isArray(value) && value.length === 0);
}

export function cleanFilterObject(obj: object): object {
	return Object.entries(obj).reduce((newFilterObject, [key, value]) => {
		if (filterNilOrEmpty(value)) {
			newFilterObject[key] = value;
		}

		return newFilterObject;
	}, {});
}

function getNewSearchQuery<T extends object>(params: {
	currentSearchQuery: string;
	resetOtherParams: boolean;
	newFilterQueryObject: T;
	trackedKeys: (keyof T)[];
}) {
	const {
		currentSearchQuery: searchQuery,
		newFilterQueryObject: updatedFilterQueryObj,
		resetOtherParams,
		trackedKeys
	} = params;

	if (resetOtherParams) {
		return qs.stringify(cleanFilterObject(updatedFilterQueryObj), qsOptions);
	}

	if (!trackedKeys) {
		// Merge it
		return qs.stringify(
			cleanFilterObject(Object.assign(parseQueryParams(searchQuery), updatedFilterQueryObj)),
			qsOptions
		);
	}

	const newFilterQueryObject = parseQueryParams(searchQuery) as T;
	trackedKeys.forEach(key => {
		const value = updatedFilterQueryObj[key];

		if (filterNilOrEmpty(value)) {
			newFilterQueryObject[key as string] = value;
			return;
		}

		delete newFilterQueryObject[key as string];
	});

	return qs.stringify(newFilterQueryObject, qsOptions);
}

export function updateLocation<T extends object>({
	newFilterQueryObject,
	trackedKeys = undefined,
	resetOtherParams,
	updateURLStrategy,
	history,
	location
}: {
	newFilterQueryObject: T;
	trackedKeys?: (keyof T)[];
	resetOtherParams: boolean;
	updateURLStrategy: UpdateURLStrategy;
	history: History;
	location: Location;
}) {
	const search = getNewSearchQuery({
		currentSearchQuery: location.search,
		resetOtherParams,
		newFilterQueryObject,
		trackedKeys
	});

	const locationObject: LocationDescriptor = {
		pathname: location.pathname,
		search
	};

	switch (updateURLStrategy) {
		case UpdateURLStrategy.REPLACE:
			history.replace(locationObject);
			break;

		case UpdateURLStrategy.ADD:
			history.push(locationObject);
			break;

		default:
			throw new Error(`unknown update url strategy - ${updateURLStrategy}`);
	}
}
