import { BaseFormat } from './types';

export function matchesEntirely(text: string, regular_expression: RegExp) {
	const safeText = text || '';
	return new RegExp('^(?:' + regular_expression + ')$').test(safeText);
}

// moved from libphonenumber-js internals, it is not exported by the lib, and has poor ty
export function chooseFormatForNumber(availableFormats: BaseFormat[], nationalNumber: string) {
	for (const format of availableFormats) {
		// Validate leading digits.
		// The test case for "else path" could be found by searching for
		// "format.leadingDigitsPatterns().length === 0".
		if (format.leadingDigitsPatterns().length > 0) {
			// The last leading_digits_pattern is used here, as it is the most detailed
			const lastLeadingDigitsPattern = format.leadingDigitsPatterns()[format.leadingDigitsPatterns().length - 1];
			// If leading digits don't match then move on to the next phone number format
			if (nationalNumber.search(lastLeadingDigitsPattern) !== 0) {
				continue;
			}
		}
		// Check that the national number matches the phone number format regular expression
		if (matchesEntirely(nationalNumber, format.pattern())) {
			return format;
		}
	}
}
