import { LexoRank } from 'lexorank';
import { isEqual, isNil } from 'lodash';
import { RuleConfig, RuleType } from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import { FallbackMethod } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/fallback/use-fallback';
import { Attribute } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { EarlyEtaStrategy, FeatureFlags } from '@bringg/types';

import { FleetRule as FleetRuleT, Rule } from '../../../stores/fleets-configuration/fleets-configurations-store';

export interface FleetRuleItemProps {
	rule: FleetRuleT;
	index: number;
	teams: Team[];
	fleets: FleetsOptions[];
	attributes: Attribute[];
	isOpen?: boolean;
	onDelete: (props: onDeleteProps) => Promise<void>;
	onSave: (rule: FleetRuleT) => Promise<boolean>;
	onToggleRule: (props: onToggleProps) => Promise<void>;
	onDuplicate: (props: Rule) => Promise<void>;
	onDragChange: React.Dispatch<React.SetStateAction<boolean>>;
	onChangeName?: any;
	featureFlags: FeatureFlags;
}

export interface PriorityConfiguration {
	fallbackMethod?: FallbackMethod;
	isFallbackMethodEnabled: boolean;
	selectedFleetIds: string[];
}

// Feature flags
export const NEW_FLEET_ROUTER_RULES_FF = 'fleet_router_rules_with_delivery_terms';
export const ENABLE_VENDOR_ALLOCATION_RULE_FF = 'enable_vendor_allocation_rule';
export const ENABLE_FASTEST_FLEET_OPTIONS_FF = 'fleet_router_rules_pickup_eta_available';

export interface FleetsOptions {
	key: string;
	value: string;
	ratio?: number;
	error?: boolean;
	disabled?: boolean;
}
export interface Team {
	id: string;
	name: string;
}

export interface onToggleProps {
	value: boolean;
	id: string;
}

export interface onDeleteProps {
	ruleId: string;
	conditionsId: string;
}

export enum FallbackOptions {
	FAST = 'fast',
	CHEAP = 'cheap'
}

export const ruleTypeMap = {
	cheapest: RuleType.cheap,
	fastest: RuleType.fast,
	priority: RuleType.priority,
	ratio: RuleType.ratio
};

export type FleetRuleConfig = RuleConfig & {
	fleetsRatio?: FleetRatioMap;
};

export interface FleetsRatio {
	fleet_id: string;
	ratio: number;
}

interface GetPriorityType {
	rawList: FleetRuleT[];
	to?: number;
	from?: number;
}

export interface FleetRatioMap {
	[key: string]: number;
}

export const getPriority = ({ rawList, to, from }: GetPriorityType): string => {
	if (!rawList.length) {
		return LexoRank.middle().toString();
	}

	const list = rawList.sort((a, b) => {
		return a.priority.localeCompare(b.priority);
	});

	let rank: LexoRank;

	const toStart = to === 0;
	const toEnd = isNil(to) || to === list.length;
	const between = !toStart && !toEnd;

	if (toStart) {
		const first = LexoRank.parse(list[0].priority);
		rank = first.genPrev();
	}

	if (toEnd) {
		const last = LexoRank.parse(list[list.length - 1].priority);
		rank = last.genNext();
	}

	if (between) {
		let first: LexoRank;
		let second: LexoRank;

		if (to <= from) {
			first = to > 0 ? LexoRank.parse(list[to - 1].priority) : LexoRank.min();
			second = LexoRank.parse(list[to].priority);
		} else {
			first = LexoRank.parse(list[to - 1].priority);
			second = to < list.length - 1 ? LexoRank.parse(list[to].priority) : LexoRank.max();
		}

		rank = first.between(second);
	}

	return rank.toString();
};

interface FleetsConfigurationStore {
	updateRulePriority(rule: FleetRuleT): Promise<void>;
}

export const assignPrioritiesToInitialRules = async (
	fleetsConfigurationStore: FleetsConfigurationStore,
	rulesList: FleetRuleT[]
): Promise<void> => {
	rulesList.forEach((rule, i) => {
		const isFirst = i === 0;
		let newPriority: string;

		if (isFirst) {
			newPriority = LexoRank.middle().toString();
		} else {
			const prevRank = LexoRank.parse(rulesList[i - 1].priority);
			newPriority = prevRank.genNext().toString();
		}

		rule.priority = newPriority;
	});

	for (const rule of rulesList) {
		await fleetsConfigurationStore.updateRulePriority(rule);
	}
};

export const handleFallbackMethod = (fallbackMethod: FallbackMethod): FallbackMethod => {
	if (fallbackMethod) {
		return fallbackMethod;
	}

	return FallbackMethod.FAST;
};

export const createRuleConfig = ({
	rule,
	fleets,
	featureFlags
}: {
	rule: FleetRuleT;
	fleets: string[];
	featureFlags: FeatureFlags;
}): FleetRuleConfig & { isEnabled: boolean; conditionEntity: any } => {
	const defaultTypesForConfiguration = [RuleType.priority, RuleType.ratio];

	if (!featureFlags?.pickupEtaAvailable) {
		return {
			id: rule.id,
			name: rule.name,
			isEnabled: rule.isEnabled,
			ruleType: rule.ruleType,
			fleetsRatio: rule.fleetsRatio,
			conditionEntity: rule.conditionEntity,
			isMerchantLevel: rule.isMerchantLevel,
			selectedTeams: rule.selectedTeams,
			...(defaultTypesForConfiguration.includes(rule.ruleType) && {
				priorityConfiguration: {
					...(rule.priorityConfiguration?.isFallbackMethodEnabled && {
						fallbackMethod: handleFallbackMethod(rule.priorityConfiguration.fallbackMethod)
					}),
					isFallbackMethodEnabled: Boolean(rule.priorityConfiguration?.isFallbackMethodEnabled),
					selectedFleetIds: fleets && fleets.length ? fleets : []
				}
			})
		};
	}

	const priorityConfigurationTypes = [...defaultTypesForConfiguration];

	const priorityConfiguration =
		buildPriorityConfiguration({
			rule,
			types: priorityConfigurationTypes,
			fleets
		}) || {};

	const earlyEtaStrategy = buildEarlyEtaStrategy(rule) || {};

	return {
		id: rule.id,
		name: rule.name,
		isEnabled: rule.isEnabled,
		ruleType: rule.ruleType,
		fleetsRatio: rule.fleetsRatio,
		conditionEntity: rule.conditionEntity,
		isMerchantLevel: rule.isMerchantLevel,
		selectedTeams: rule.selectedTeams,
		...earlyEtaStrategy,
		...priorityConfiguration
	};
};

const buildPriorityConfiguration = ({
	rule,
	types,
	fleets
}: {
	rule: FleetRuleT;
	types: RuleType[];
	fleets: string[];
}): { priorityConfiguration: PriorityConfiguration } | null => {
	if (types.includes(rule.ruleType)) {
		return {
			priorityConfiguration: {
				...(rule.priorityConfiguration?.isFallbackMethodEnabled && {
					fallbackMethod: handleFallbackMethod(rule.priorityConfiguration.fallbackMethod)
				}),
				isFallbackMethodEnabled: Boolean(rule.priorityConfiguration?.isFallbackMethodEnabled),
				selectedFleetIds: fleets && fleets.length ? fleets : []
			}
		};
	}

	return null;
};

const buildEarlyEtaStrategy = (rule: FleetRuleT): { earlyEtaStrategy: EarlyEtaStrategy | null } => {
	if (rule.ruleType === RuleType.fast) {
		return { earlyEtaStrategy: rule.earlyEtaStrategy || EarlyEtaStrategy.DROPOFF };
	}

	return null;
};

export const sortByPriority = (a: FleetRuleT, b: FleetRuleT): number => {
	if (a.priority < b.priority) return -1;
	if (a.priority > b.priority) return 1;
	return 0;
};

export const mapFleets = (fleets: FleetsOptions[]): FleetsOptions[] =>
	fleets.map(fleet => ({ value: fleet.key, key: fleet.value, disabled: fleet.disabled }));

export const getFleetsByIds = ({
	fleets,
	ids,
	fleetsRatioMap
}: {
	fleets: FleetsOptions[];
	ids: string[];
	fleetsRatioMap: FleetRatioMap;
}): FleetsOptions[] => {
	const fleetMap = new Map(
		fleets.map(fleet => [fleet.value, { ...fleet, ratio: fleetsRatioMap?.[fleet.value] ?? 0, error: false }])
	);

	return ids.map(id => fleetMap.get(id)).filter(fleet => fleet !== undefined);
};

export interface DragResult {
	source: { index: number };
	destination: { index: number };
}

export const handleDrag = ({ options, result }: { options: FleetsOptions[]; result: DragResult }): FleetsOptions[] => {
	const itemsCopy = Array.from(options);
	const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
	itemsCopy.splice(result.destination.index, 0, reorderedItem);

	return itemsCopy;
};

export const validateFleetsIds = ruleForm => {
	const { priorityConfiguration, ruleType } = ruleForm;

	const ruleTypesWithMultiselect = [RuleType.priority, RuleType.ratio];

	const shouldValidateRule = ruleTypesWithMultiselect.includes(ruleType);

	if (shouldValidateRule && !priorityConfiguration?.selectedFleetIds?.length) {
		return false;
	}

	return true;
};

export const validateEquality = (newRule, rule) => {
	const rulesAreEqual = isEqual(newRule, rule);

	return !rulesAreEqual;
};

export const validateTeams = ruleForm => {
	const { selectedTeams, isMerchantLevel } = ruleForm;

	if (!isMerchantLevel && Object.keys(selectedTeams).length === 0) {
		return false;
	}

	return true;
};

export enum ConditionDataTypes {
	NUMBER = 'NUMBER',
	LIST = 'LIST',
	STRING = 'STRING',
	ARRAY = 'ARRAY'
}

const defaultSimpleOperatorCheck = condition => {
	if (!condition?.operator) {
		for (const item of condition.value) {
			if (!item?.operator) {
				return false;
			}
		}
	}

	return true;
};

const listsWithoutOperator = ['service_plan_id', 'task_type_id'];

const defaultListOperatorCheck = condition => {
	if (!listsWithoutOperator.includes(condition.path) && !condition?.operator) {
		return false;
	}

	return true;
};

const validateConditionsByType = {
	[ConditionDataTypes.NUMBER]: defaultSimpleOperatorCheck,
	[ConditionDataTypes.LIST]: defaultListOperatorCheck,
	[ConditionDataTypes.STRING]: defaultSimpleOperatorCheck,
	[ConditionDataTypes.ARRAY]: defaultListOperatorCheck
};

export const validateConditions = ruleForm => {
	const { conditionEntity } = ruleForm;

	if (!conditionEntity || !conditionEntity.conditions) {
		return true;
	}

	return conditionEntity.conditions.every(condition => {
		const validateCondition = validateConditionsByType[condition.type];

		if (!validateCondition) {
			return true;
		}

		return validateCondition(condition);
	});
};

export const validateRuleForm = ({ ruleForm }: { ruleForm: FleetRuleT }): FleetRuleT => {
	const { conditionEntity } = ruleForm;

	if (conditionEntity?.conditions?.length > 0) {
		conditionEntity.conditions = conditionEntity.conditions.filter(({ value }) => value && value.length > 0);
	}

	ruleForm.conditionEntity = conditionEntity;

	return ruleForm;
};

export const getAttributesLength = ({ attributes }: { attributes: Attribute[] }): number => {
	let num = 0;

	attributes.forEach(attribute => {
		if (attribute.attributes) {
			num += attribute.attributes.length;
		} else {
			num++;
		}
	});

	return num;
};

export const filteredAttributes = ({
	attributes,
	usedAttributes
}: {
	attributes: Attribute[];
	usedAttributes: string[];
}): Attribute[] =>
	[...attributes].reduce((filtered: any[], attribute) => {
		if (attribute.path && usedAttributes.includes(attribute.path)) {
			return filtered;
		}

		if (!attribute.path && attribute.attributes) {
			const filteredNested = attribute.attributes.filter(
				(nestedAttr: any) => !usedAttributes.includes(nestedAttr.path)
			);

			if (filteredNested.length > 0) {
				filtered.push({ ...attribute, attributes: filteredNested });
			}
		} else {
			filtered.push(attribute);
		}

		return filtered;
	}, []);

export const getTotalExcludingCurrent = (fleetsRatio: FleetsRatio[], currentFleet: FleetsRatio): number => {
	const fleets = [...fleetsRatio.filter(fleet => fleet.fleet_id !== currentFleet.fleet_id)];

	const total = fleets.reduce((acc, fleet) => acc + fleet.ratio, 0);

	return total;
};

export const handleRatio = ({
	value,
	fleet,
	fleetsRatio
}: {
	value: number;
	fleet: FleetsOptions;
	fleetsRatio: FleetsRatio[];
}): FleetsRatio[] => {
	if (fleetsRatio.some(fleetRatio => fleetRatio.fleet_id === fleet.value)) {
		const updatedFleetsRatio = fleetsRatio.map(fleetRatio => {
			if (fleetRatio.fleet_id === fleet.value) {
				return { ...fleetRatio, ratio: value };
			}

			return fleetRatio;
		});

		return updatedFleetsRatio;
	} else {
		return [...fleetsRatio, { fleet_id: fleet.value, ratio: value }];
	}
};

export const handleRatioItemError = (draggableOptions: FleetsOptions[]): FleetsOptions[] =>
	draggableOptions.map(fleet => ({ ...fleet, error: fleet.ratio === 0 }));

export const updateDraggableOptions = ({
	draggableOptions,
	updatedRatio
}: {
	draggableOptions: FleetsOptions[];
	updatedRatio: { fleet_id: string; ratio: number }[];
}): FleetsOptions[] => {
	const ratioMap = new Map(updatedRatio.map(fleet => [fleet.fleet_id, fleet.ratio]));

	return draggableOptions.map(option => {
		const ratio = ratioMap.get(option.value);

		if (ratio === undefined) {
			return option;
		}

		return { ...option, ratio };
	});
};

export const processInputValue = (rawValue: string): number => {
	if (rawValue.length === 1 && !rawValue.includes('%')) {
		return 0;
	} else if (!rawValue.includes('%')) {
		return Number(rawValue.split('')[0]);
	} else {
		return Number(rawValue.replace('%', ''));
	}
};

export const getTotalPercents = (fleetsRatio: FleetsRatio[]): number =>
	fleetsRatio.reduce((acc, fleet) => acc + fleet.ratio, 0);

export const mapFleetRatio = (fleetRatio): FleetRatioMap =>
	fleetRatio.reduce((result, fleetRatio) => {
		result[fleetRatio.fleet_id] = fleetRatio.ratio;
		return result;
	}, {});
