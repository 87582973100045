import { useCallback } from 'react';

import { Button, CsvTemplateDownloader } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

export type Props = {
	headers: string[];
	rows?: string[][];
	fileName: string;
	children?: JSX.Element;
};

function downloadCsv(headers: string[], rows: string[][], fileName: string) {
	const csv = [headers, ...rows].map(row => row.join(',')).join('\n');
	const link = window.document.createElement('a');
	link.href = CsvTemplateDownloader.createFileValue(csv);
	link.download = `${fileName}.csv`;
	link.click();
	link.remove();
}

function DownloadCsvTemplate({ headers, rows = [], fileName, children }: Props) {
	const { t } = useTranslation();
	const downloadTemplate = useCallback(() => downloadCsv(headers, rows, fileName), [headers, fileName, rows]);

	return (
		<span onClick={downloadTemplate}>
			{children ? (
				children
			) : (
				<Button style={{ padding: 0, fontSize: 12 }} type="link">
					{t('FILE_UPLOADER.DOWNLOAD_TEMPLATE')}
				</Button>
			)}
		</span>
	);
}

export default DownloadCsvTemplate;
