import React from 'react';

import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { dateUtils } from '@bringg-frontend/utils';

import { useStores } from 'bringg-web/recipes';
import { PlannedDeliveryWindowsModalCalledFrom } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';

import styles from './modal-header.module.scss';

interface Props {
	selectedPlannedDeliveryWindows: PlannedDeliveryWindow[];
	timezone: string;
	calledFrom?: PlannedDeliveryWindowsModalCalledFrom;
}
const ModalHeader = ({
	selectedPlannedDeliveryWindows,
	timezone,
	calledFrom = PlannedDeliveryWindowsModalCalledFrom.MANUAL
}: Props) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();

	const getEffectiveDatesText = () => {
		const isAllEffectiveStartTimeAreSame = selectedPlannedDeliveryWindows[0]?.effective_start_date
			? selectedPlannedDeliveryWindows.every(
					pdw => pdw.effective_start_date === selectedPlannedDeliveryWindows[0].effective_start_date
			  )
			: false;
		const isAllEffectiveEndTimeAreSame = selectedPlannedDeliveryWindows[0]?.effective_end_date
			? selectedPlannedDeliveryWindows.every(
					pdw => pdw.effective_end_date === selectedPlannedDeliveryWindows[0].effective_end_date
			  )
			: false;

		const effectiveStartDate = moment(selectedPlannedDeliveryWindows[0].effective_start_date)
			.tz(timezone)
			.format('DD/MM/YYYY');
		const effectiveEndDate = moment(selectedPlannedDeliveryWindows[0].effective_end_date)
			.tz(timezone)
			.format('DD/MM/YYYY');

		if (isAllEffectiveStartTimeAreSame && isAllEffectiveEndTimeAreSame) {
			return `${effectiveStartDate} - ${effectiveEndDate}`;
		}

		if (!isAllEffectiveStartTimeAreSame && !isAllEffectiveEndTimeAreSame) {
			return t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT');
		}

		const text = isAllEffectiveStartTimeAreSame
			? effectiveStartDate
			: t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT');
		return `${text} ${
			isAllEffectiveEndTimeAreSame
				? ` - ${effectiveEndDate}`
				: ` - ${t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT')}`
		}`;
	};

	const getTimeText = () => {
		const isAllStartTimeAreSame = selectedPlannedDeliveryWindows.every(pdw => {
			const firstPdwStartTimeHours = dateUtils
				.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].start_time, timezone)
				.hours();
			const firstPdwStartTimeMinutes = dateUtils
				.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].start_time, timezone)
				.minutes();
			const currentPdwStartTimeHours = dateUtils.addMinutesToLastMonday(pdw.start_time, timezone).hours();
			const currentPdwStartTimeMinutes = dateUtils.addMinutesToLastMonday(pdw.start_time, timezone).minutes();

			return (
				firstPdwStartTimeHours === currentPdwStartTimeHours &&
				firstPdwStartTimeMinutes === currentPdwStartTimeMinutes
			);
		});
		const isAllEndTimeAreSame = selectedPlannedDeliveryWindows.every(pdw => {
			const firstPdwEndTimeHours = dateUtils
				.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].end_time, timezone)
				.hours();
			const firstPdwEndTimeMinutes = dateUtils
				.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].end_time, timezone)
				.minutes();
			const currentPdwEndTimeHours = dateUtils.addMinutesToLastMonday(pdw.end_time, timezone).hours();
			const currentPdwEndTimeMinutes = dateUtils.addMinutesToLastMonday(pdw.end_time, timezone).minutes();

			return (
				firstPdwEndTimeHours === currentPdwEndTimeHours && firstPdwEndTimeMinutes === currentPdwEndTimeMinutes
			);
		});

		const timeFormat = dateUtils.getMerchantTimeFormat(merchantConfigurationsStore.hourFormat);
		const startTimeFormatted = dateUtils
			.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].start_time, timezone)
			.format(timeFormat);
		const endTimeFormatted = dateUtils
			.addMinutesToLastMonday(selectedPlannedDeliveryWindows[0].end_time, timezone)
			.format(timeFormat);

		if (isAllStartTimeAreSame && isAllEndTimeAreSame) {
			return `${startTimeFormatted} - ${endTimeFormatted}`;
		}

		if (!isAllStartTimeAreSame && !isAllEndTimeAreSame) {
			return t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT');
		}

		const text = isAllStartTimeAreSame ? startTimeFormatted : t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT');
		return `${text} ${
			isAllEndTimeAreSame
				? ` - ${endTimeFormatted}`
				: ` - ${t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.MIXED_TEXT')}`
		}`;
	};

	return (
		<div className={styles.multipleEditHeader}>
			<div className={styles.firstLine}>
				<span className={styles.lengthDeliverySlots}>
					{`${selectedPlannedDeliveryWindows.length} ${t(
						'PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.DELIVERY_SLOTS_SELECTED'
					)}`}
				</span>
				<span className={styles.basedOn}>
					{calledFrom === PlannedDeliveryWindowsModalCalledFrom.MANUAL
						? t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.BASED_ON_MANUAL_SELECTION')
						: t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.BASED_ON_FILTERS')}
				</span>
			</div>
			<div className={styles.secondLine}>
				<span className={styles.effectiveDates}>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_DATES_TEXT')}:
				</span>
				<span className={styles.effectiveDatesValue}>{getEffectiveDatesText()}</span>
				<span className={styles.time}>{t('PLANNED_DELIVERY_WINDOWS_MODAL_HEADER.TIME_TEXT')}</span>
				<span className={styles.timeValue}>{getTimeText()}</span>
			</div>
		</div>
	);
};

export default ModalHeader;
