import { useCallback, useMemo, useState } from 'react';

import { DropdownMenu, Modal, DateRangePicker, AdjustPopoverOverflow, TreeSelect } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import moment, { Moment } from 'moment';
import { cloneDeep, orderBy } from 'lodash';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { TeamServiceArea } from '@bringg/types';

import { formatCardTime } from 'bringg-web/features/service-area/utils/format-service-area-time';
import { useStores } from 'bringg-web/recipes';
import { useTeamsTreeData } from 'bringg-web/hooks';
import IconButton from 'bringg-web/components/icon-button/icon-button';

import './teams-section.scss';

const MODAL_WIDTH = 734;

function truncateString(str, maxLength) {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	}

	return str;
}

export function ServiceAreaTeamList({ teamsMap, assignedTeams = [], onChange }) {
	const { t } = useTranslation();
	const [editedTeamData, setEditedTeamData] = useState<TeamServiceArea>(null);

	const handleDragEnd = useCallback(
		result => {
			if (!result.destination) {
				return;
			}

			const { source, destination } = result;
			const newTeams = cloneDeep(assignedTeams);
			const [removed] = newTeams.splice(source.index, 1);
			newTeams.splice(destination.index, 0, removed);
			newTeams.forEach((team, index) => {
				team.priority = index + 1;
			});

			onChange(newTeams);
		},
		[assignedTeams, onChange]
	);

	const handleDateRangeUpdate = (values: Moment[]) => {
		const [startDate, endDate] = values || [];
		const teamTimezone = teamsMap[editedTeamData.team_id]?.time_zone;

		const startTimeWithTZ = startDate
			? moment(startDate).startOf('day').tz(teamTimezone, true).toISOString()
			: null;
		const endTimeWithTZ = endDate ? moment(endDate).endOf('day').tz(teamTimezone, true).toISOString() : null;

		return setEditedTeamData({
			...editedTeamData,
			effective_start_time: startTimeWithTZ,
			effective_end_time: endTimeWithTZ
		});
	};

	const handleDeleteTeam = (assignedTeam: TeamServiceArea) =>
		onChange(assignedTeams.filter(team => team !== assignedTeam));

	const getDropdownItems = (assignedTeam: TeamServiceArea) => [
		{
			key: 0,
			label: (
				<div data-test-id="edit-time-range" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Calendar} />
					{t('SERVICE_AREA.EDIT_TIME_RANGE')}
				</div>
			),
			onClick: () => setEditedTeamData(assignedTeam)
		},
		{
			key: 1,
			label: (
				<div data-test-id="go-to-team-page" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Forward} />
					{t('SERVICE_AREA.GO_TO_TEAM_PAGE')}
				</div>
			),
			onClick: () => window.open(`/#/drivers/teams/${assignedTeam.team_id}/info`, '_blank', 'noopener noreferrer')
		},
		{
			key: 2,
			label: (
				<div data-test-id="remove-team" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Trash} />
					{t('SERVICE_AREA.REMOVE_TEAM')}
				</div>
			),
			onClick: () => handleDeleteTeam(assignedTeam)
		}
	];

	const onOk = useCallback(() => {
		const editedTeamIndex = assignedTeams.findIndex(({ id }) => id === editedTeamData.id);
		assignedTeams[editedTeamIndex] = editedTeamData;

		onChange(assignedTeams);
		setEditedTeamData(null);
	}, [assignedTeams, onChange, editedTeamData, setEditedTeamData]);

	const onCancel = useCallback(() => {
		setEditedTeamData(null);
	}, [setEditedTeamData]);

	const teamTimeZone = useMemo(() => teamsMap[editedTeamData?.team_id]?.time_zone, [teamsMap, editedTeamData]);
	const startDate = useMemo(
		() =>
			editedTeamData?.effective_start_time ? moment.tz(editedTeamData.effective_start_time, teamTimeZone) : null,
		[teamTimeZone, editedTeamData]
	);
	const endDate = useMemo(
		() => (editedTeamData?.effective_end_time ? moment.tz(editedTeamData.effective_end_time, teamTimeZone) : null),
		[teamTimeZone, editedTeamData]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			{editedTeamData && (
				<Modal
					width={MODAL_WIDTH}
					data-test-id="edit-time-range-modal"
					className="service-area-teams-section-modal"
					open
					onOk={onOk}
					onCancel={onCancel}
					transitionName=""
					maskTransitionName=""
				>
					<h2 className="range-header">{t('SERVICE_AREA.TIME_RANGE')}</h2>
					<div className="range-description">
						{t('SERVICE_AREA.TIME_RANGE_DESCRIPTION', {
							name: truncateString(teamsMap[editedTeamData.team_id]?.name, 50)
						})}
					</div>
					<DateRangePicker
						picker="date"
						dropdownClassName="service-area-teams-section-range-picker"
						translations={{
							fromDate: t('SERVICE_AREA.START_DATE'),
							toDate: t('SERVICE_AREA.END_DATE')
						}}
						open
						startDate={startDate}
						endDate={endDate}
						allowEmpty={[true, true]}
						onChange={handleDateRangeUpdate}
					/>
				</Modal>
			)}
			<Droppable droppableId="teams">
				{dropProvided => (
					<div
						data-test-id="team-section-cards"
						className="team-section-cards"
						{...dropProvided.droppableProps}
						ref={dropProvided.innerRef}
					>
						{assignedTeams.map((team, index) => {
							const teamData = teamsMap[team.team_id];
							const teamEffectiveRange = formatCardTime(
								team.effective_start_time,
								team.effective_end_time,
								teamsMap[team.team_id]?.time_zone
							);

							return (
								<Draggable key={team.team_id} draggableId={String(team.team_id)} index={index}>
									{dragProvided => (
										<div
											className="team-section-card"
											{...dragProvided.draggableProps}
											ref={dragProvided.innerRef}
										>
											<div
												className="centered-container team-section-draggable"
												{...dragProvided.dragHandleProps}
											>
												<BringgIcon iconName={BringgFontIcons.Drag} />
											</div>
											<div className="team-section-main">
												<h2 className="team-section-header">{teamData?.name}</h2>
												<div className="team-section-info">
													{teamData?.external_id && (
														<div className="team-section-badge">
															ID {teamData.external_id}
														</div>
													)}
													{teamEffectiveRange && (
														<div className="team-section-badge">{teamEffectiveRange}</div>
													)}
												</div>
											</div>
											<DropdownMenu
												adjustOverflow={AdjustPopoverOverflow.DO_NOT_ADJUST}
												destroyOnHide
												items={getDropdownItems(team)}
												placement="bottom-end"
												trigger={['click']}
											>
												<div className="centered-container">
													<IconButton
														data-test-id="team-section-menu-icon"
														icon={BringgFontIcons.Menu}
													/>
												</div>
											</DropdownMenu>
										</div>
									)}
								</Draggable>
							);
						})}
						{dropProvided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export type Props = {
	value?: Partial<TeamServiceArea>[];
	onChange?: (teams: Partial<TeamServiceArea>[]) => void;
};

export const ServiceAreaTeamsSection = observer(({ value = [], onChange }: Props) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	const teams = useTeamsTreeData(teamsStore.all);

	const teamsMap = useMemo(
		() => teamsStore.all.reduce((acc, team) => ({ ...acc, [team.id]: team }), {}),
		[teamsStore.all]
	);

	const onTeamSelected = useCallback(
		teamIds => {
			const selectedTeams = teamsStore.all.filter(team => teamIds.includes(team.id));
			const maxPriority = Math.max(...value.map(assignedTeam => assignedTeam.priority).filter(Number.isInteger)); // could be -Infinity
			const nextPriority = Number.isFinite(maxPriority) ? maxPriority + 1 : 1;

			onChange(
				orderBy(
					selectedTeams.map(team => {
						const assigned = value.find(assignedTeam => assignedTeam.team_id === team.id) || null;

						return {
							// default values
							team_id: team.id,
							priority: nextPriority,
							effective_start_time: new Date().toISOString(),
							// put existing values if assigned exists
							...assigned
						};
					}),
					'priority'
				)
			);
		},
		[teamsStore.all, value, onChange]
	);

	return (
		<div className="teams-section">
			<h2 className="header-info">{t('SERVICE_AREA.TEAMS')}</h2>
			<div className="header-description">{t('SERVICE_AREA.TEAMS_DESCRIPTION')}</div>
			<TreeSelect
				getPopupContainer={trigger => trigger.parentNode}
				allowSelectAll
				selectAllText={t('MULTI_SELECT.SELECT_ALL')}
				placeholder={t('SERVICE_AREA.ADD_TEAM')}
				treeData={teams}
				onChange={onTeamSelected}
				value={value?.map(t => t.team_id).sort() || []}
			/>

			<ServiceAreaTeamList teamsMap={teamsMap} assignedTeams={value} onChange={onChange} />
		</div>
	);
});
