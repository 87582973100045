import { isEqual } from 'lodash';
import _intersection from 'lodash/intersection';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';
import { Attribute } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { Rule } from '@bringg/react-components';
import { FallbackMethod } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/fallback/use-fallback';
import { RuleType } from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import { Rule as ServerRule } from '@bringg/dashboard-sdk/dist/FleetRouter/types';
import { FeatureFlags, FleetCapabilities, MetadataResponse, RuleCategory } from '@bringg/types';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';
import { difference } from '@bringg-frontend/utils';

import {
	formatRuleFromServer,
	formatRuleFromServerWithEarlyEtaStrategy,
	formatRuleToServer,
	_mapFleetsRatioFromServer,
	formatRuleToServerWithEarlyEtaStrategy
} from './adapters';
import {
	getAttributes,
	getFlatAttribute,
	mapRulesToServer,
	mapRulesFromServer
} from 'bringg-web/features/rule-engine/utils/mapper';
import {
	assignPrioritiesToInitialRules,
	FleetRuleConfig,
	NEW_FLEET_ROUTER_RULES_FF,
	ENABLE_FASTEST_FLEET_OPTIONS_FF,
	getPriority
} from 'bringg-web/features/fleets-configuration/rules-list/rule-list-utils';
import {
	defaultConditions,
	getConditionsPayload,
	updateConditions,
	sortRules,
	getConditions
} from './fleets-configurations-store.utils';

export type Rule = FleetRuleConfig & {
	rule_id?: string;
	isEnabled: boolean;
	priority?: string;
	attributes?: Attribute[];
	conditionEntity?: any;
	isOpen?: boolean;
};

export type FleetRule = Rule & {
	isFallbackMethodEnabled?: boolean;
	fallbackMethod?: FallbackMethod;
	selectedFleetIds?: string[];
};

interface Fleet {
	id: string;
	name: string;
	capabilities: FleetCapabilities;
	pickupEtaAvailable?: boolean;
}

export type Fleets = {
	[id: string]: Fleet;
};

export const getSelectedTeams = rule => Object.keys(rule.selectedTeams).filter(teamId => !!rule.selectedTeams[teamId]);

class FleetsConfigurationStore {
	fleets: Fleets = {};
	rules: Rule[] = [];
	metadata: MetadataResponse;
	attributes: Attribute[] = [];
	attributesByPath: { [path: string]: Attribute };
	featureFlags: FeatureFlags;

	private loaded = false;

	constructor() {
		makeObservable(this, {
			fleets: observable,
			rules: observable,
			featureFlags: observable,
			deleteRule: action,
			deleteRuleOld: action,
			createRule: action,
			updateRule: action,
			patchRules: action,
			conflictedRules: computed
		});
	}

	async load() {
		if (this.loaded) {
			return;
		}

		this.loaded = true;

		const { sdk } = getRootEnv().dashboardSdk;
		const merchant = sdk.session.merchant;

		const featureFlags = merchant.feature_flags;

		const [fleets, rules] = await Promise.all([sdk.fleets.getAll(), sdk.fleetRouterApi.getAll()]);

		let metadata;
		let rulesWithConditions;

		if (featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			const metadataPromise = this.metadata
				? this.metadata
				: sdk.rules.getMetadata({ category_id: RuleCategory.FleetRouterRulePolicy });

			metadata = await metadataPromise;

			// Enabled rule with isMerchantLevel = true is always last
			let rulesList;

			if (featureFlags?.[ENABLE_FASTEST_FLEET_OPTIONS_FF]) {
				rulesList = rules.routing_rules
					.map(rule => formatRuleFromServerWithEarlyEtaStrategy(rule))
					.sort(sortRules);
			} else {
				rulesList = rules.routing_rules.map(rule => formatRuleFromServer(rule)).sort(sortRules);
			}

			const hasPriority = rulesList.every(rule => rule.priority !== null);

			if (!hasPriority) {
				await assignPrioritiesToInitialRules(this, rulesList);
			}

			this.attributes = getAttributes(metadata, true).filter(attribute => attribute.path !== 'team_id');
			this.attributesByPath = getFlatAttribute(this.attributes);

			rulesWithConditions = await getConditions(rulesList, sdk, this.attributesByPath);
		}

		runInAction(() => {
			this.fleets = fleets.reduce((result, fleet) => {
				// eslint-disable-next-line no-param-reassign
				result[fleet.id] = {
					id: fleet.id.toString(),
					name: fleet.name,
					capabilities: fleet.capabilities,
					fleet_type: fleet.fleet_type
				};
				return result;
			}, {});

			this.featureFlags = featureFlags;

			if (featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
				this.metadata = metadata;
				this.rules = rulesWithConditions;
			} else {
				this.rules = rules.routing_rules.map(rule => formatRuleFromServer(rule));
			}
		});
	}

	deleteConditions = async id => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;

		await sdk.rules.saveRules({
			deleted: [id]
		});
	};

	deleteRule = async ({ ruleId, conditionsId }) => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;
		await sdk.fleetRouterApi.delete(ruleId);
		const index = this.rules.findIndex(rule => rule.id === ruleId);
		this.rules.splice(index, 1);

		if (conditionsId) {
			await this.deleteConditions(conditionsId);
		}
	};

	deleteRuleOld = async (id: string) => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;
		await sdk.fleetRouterApi.delete(id);
		const index = this.rules.findIndex(rule => rule.id === id);
		this.rules.splice(index, 1);
	};

	createConditions = async (ruleId, conditionEntity, sdk) => {
		delete conditionEntity.id;
		delete conditionEntity.guid;

		const conditionsResult = await sdk.rules.saveRules({
			created: mapRulesToServer([conditionEntity], getConditionsPayload(ruleId))
		});

		const mapped = mapRulesFromServer(conditionsResult?.created || [], this.attributesByPath);

		return mapped[0];
	};

	createRule = async (rule: Rule) => {
		const { sdk } = getRootEnv().dashboardSdk;
		let result;

		if (this.featureFlags?.[ENABLE_FASTEST_FLEET_OPTIONS_FF]) {
			result = await sdk.fleetRouterApi.create(formatRuleToServerWithEarlyEtaStrategy(rule));
		} else {
			result = await sdk.fleetRouterApi.create(formatRuleToServer(rule));
		}

		const id = result.routing_rule.fleet_router_rules_group_id;

		if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			const conditionEntity = await this.createConditions(result.routing_rule.rule_id, rule.conditionEntity, sdk);

			rule.conditionEntity = conditionEntity;

			this.rules.unshift({ ...rule, id });
		} else {
			this.rules.push({ ...rule, id });
		}
	};

	updateRule = async (updatedRule: Rule) => {
		const index = this.rules.findIndex(rule => rule.id === updatedRule.id);
		const rule = this.rules[index];

		let originalRule, formattedUpdatedRule;

		if (this.featureFlags?.[ENABLE_FASTEST_FLEET_OPTIONS_FF]) {
			originalRule = formatRuleToServerWithEarlyEtaStrategy(rule);
			formattedUpdatedRule = formatRuleToServerWithEarlyEtaStrategy(updatedRule);
		} else {
			originalRule = formatRuleToServer(rule);
			formattedUpdatedRule = formatRuleToServer(updatedRule);
		}

		const diff = difference<Partial<ServerRule>>(formattedUpdatedRule, originalRule);

		if (updatedRule.isMerchantLevel && updatedRule.isEnabled && this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			diff.priority = getPriority({ rawList: [...this.rules], to: this.rules.length, from: index });
		}

		const { sdk } = getRootEnv().dashboardSdk;

		await sdk.fleetRouterApi.update(rule.id, diff);

		this.rules[index] = {
			...updatedRule,
			fleetsRatio: Array.isArray(updatedRule.fleetsRatio)
				? _mapFleetsRatioFromServer(updatedRule.fleetsRatio)
				: updatedRule.fleetsRatio
		};

		if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			this.rules.sort(sortRules);
		}

		if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			const shouldUpdateConditions = !isEqual(
				rule.conditionEntity?.conditions,
				updatedRule.conditionEntity?.conditions
			);

			if (shouldUpdateConditions) {
				if (!rule.conditionEntity.id) {
					const conditionEntity = await this.createConditions(rule.rule_id, updatedRule.conditionEntity, sdk);

					updatedRule.conditionEntity = conditionEntity;
				} else {
					await updateConditions(updatedRule, sdk);
				}
			}
		}
	};

	updateRulePriority = async (ruleToUpdate: Rule): Promise<void> => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;
		await sdk.fleetRouterApi.update(ruleToUpdate.id, { priority: ruleToUpdate.priority });
	};

	patchRules = async (
		params: {
			[key: string]: any;
		}[]
	) => {
		const { sdk } = getRootEnv().dashboardSdk;
		const { routing_rules } = await sdk.fleetRouterApi.patch(params);

		const formattedRulesById = routing_rules.reduce((rulesById, rule) => {
			if (rule.success) {
				let formattedRule;
				if (this.featureFlags?.[ENABLE_FASTEST_FLEET_OPTIONS_FF]) {
					formattedRule = formatRuleFromServerWithEarlyEtaStrategy(rule.routing_rule);
				} else {
					formattedRule = formatRuleFromServer(rule.routing_rule);
				}

				if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
					const existingRule = this.rules.find(r => r.id === formattedRule.id);

					// eslint-disable-next-line no-param-reassign
					const mergedRule = { ...existingRule, ...formattedRule };

					// eslint-disable-next-line no-param-reassign
					rulesById[mergedRule.id] = mergedRule;
				} else {
					rulesById[formattedRule.id] = formattedRule;
				}
			}
			return rulesById;
		}, {});

		this.rules = [...this.rules.map(rule => formattedRulesById[rule.id] || rule)];

		if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			this.rules.sort(sortRules);
		}
	};

	getRule = (ruleId: string) => {
		return this.rules.find(rule => rule.id === ruleId);
	};

	get conflictedRules() {
		return this.getConflictRulesIfRuleWouldEqual();
	}

	getConflictRulesIfRuleWouldEqual(ruleToChange?: Rule) {
		if (this.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF]) {
			const rules = [...this.rules];

			if (ruleToChange) {
				const index = rules.findIndex(rule => rule.id === ruleToChange.id);
				rules[index] = ruleToChange;
			}

			return rules.reduce((conflicts, currRule) => {
				// eslint-disable-next-line no-param-reassign
				conflicts[currRule.id] = rules.filter(rule => {
					if (ruleToChange && rule.id === ruleToChange.id) {
						// eslint-disable-next-line no-param-reassign
						rule = ruleToChange;
					}

					if (!rule.isEnabled) {
						return false;
					}

					if (rule.id === currRule.id) {
						return false;
					}

					if (currRule.isMerchantLevel) {
						return rule.isMerchantLevel;
					}
				});

				return conflicts;
			}, {});
		} else {
			return this.rules.reduce((conflicts, currRule) => {
				if (ruleToChange && currRule.id === ruleToChange.id) {
					// eslint-disable-next-line no-param-reassign
					currRule = ruleToChange;
				}

				// eslint-disable-next-line no-param-reassign
				conflicts[currRule.id] = this.rules.filter(rule => {
					if (ruleToChange && rule.id === ruleToChange.id) {
						// eslint-disable-next-line no-param-reassign
						rule = ruleToChange;
					}

					if (!rule.isEnabled) {
						return false;
					}

					if (rule.id === currRule.id) {
						return false;
					}

					if (currRule.isMerchantLevel) {
						return rule.isMerchantLevel;
					}

					return _intersection(getSelectedTeams(rule), getSelectedTeams(currRule)).length !== 0;
				});
				return conflicts;
			}, {});
		}
	}

	getAssignedTeams(selectedRuleId): Set<string> {
		return _reduce(
			this.rules,
			(assignedTeams, rule) => {
				if (rule.isEnabled && rule.id !== selectedRuleId) {
					_forEach(Object.entries(rule.selectedTeams), ([teamId, isSelected]) => {
						if (isSelected) {
							assignedTeams.add(teamId);
						}
					});
				}
				return assignedTeams;
			},
			new Set()
		);
	}

	static defaultRuleConfiguration: Rule = {
		id: null,
		name: 'Enter Rule Name',
		isMerchantLevel: false,
		selectedTeams: {},
		ruleType: RuleType.fast,
		isEnabled: false,
		isOpen: false,
		conditionEntity: defaultConditions
	};

	static defaultRuleConfigurationOld: Rule = {
		id: null,
		name: '',
		isEnabled: true,
		ruleType: 'fast' as RuleType,
		isMerchantLevel: true,
		isOpen: false,
		selectedTeams: {}
	};
}

export default FleetsConfigurationStore;
