import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Team } from '@bringg/types';
import { ResourceUploadType } from '@bringg/dashboard-sdk';
import { ResourceProcessDoneResult } from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceResults';
import { CSVUploaderModal } from '@bringg/react-components';

import notification from '../../../../../services/notification';
import useStores from '../../../../../recipes/use-stores';
import { useCsvTranslations } from './use-team-zip-codes-translations';
import resourceUploader from '../../../../../services/resource-upload/resource-uploader';

interface Props {
	team: Team;
	afterUploadDone: () => void;
	afterModalClose: () => void;
}

const TeamZipCodesUpload: React.FC<Props> = ({ team, afterUploadDone, afterModalClose }) => {
	const { teamsStore } = useStores();
	const { t } = useTranslation();
	const ZIP_CODES_IMPORT_DONE_EVENT = 'team zip codes import done';

	const importZipCodesCsv = useCallback(
		async (_, csvFile: Blob): Promise<any> => {
			const result = {
				errors: [],
				failed_number: 0,
				imported_number: 0,
				uploaded_number: 0
			};

			try {
				const uploadResult: ResourceProcessDoneResult = await resourceUploader.upload(
					csvFile,
					ResourceUploadType.ZipCodes,
					ZIP_CODES_IMPORT_DONE_EVENT,
					{
						team_id: team.id
					}
				);

				result.imported_number = uploadResult.imported;
				result.uploaded_number = uploadResult.uploaded;
				result.failed_number = uploadResult.failed;
				result.errors = uploadResult.errors;
				afterUploadDone();
			} catch (e) {
				console.error(e);
				notification.error(t('TEAM.ZIP_CODES_UPLOAD.ERROR_MESSAGE'));
			}

			return result;
		},
		[teamsStore, t]
	);

	const headers = useMemo(() => {
		return [
			{
				field_name: 'zip code',
				field_index: 1
			}
		];
	}, []);

	return (
		<CSVUploaderModal
			modalProps={{ width: 1000 }}
			headers={headers}
			onUpload={importZipCodesCsv}
			onClose={afterModalClose}
			tableProps={{
				className: 'vehicle-csv-uploader-table'
			}}
			showFirstRow={true}
			showDownloadTemplate={false}
			texts={{ ...useCsvTranslations() }}
		/>
	);
};

export default TeamZipCodesUpload;
