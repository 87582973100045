import React, { useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { DragDropContext, DropResult, DraggableLocation, DragStart } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { Collapse } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import SearchBar from './components/search-bar/search-bar';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import DropContent from './components/drop-content/dropContent';
import { DraggableItem, DraggableListSelectorTranslations, ListsType, UserDraggableItem } from './types';
import { checkDirty, mapListForApply, move, reorder } from './utils/drag-utils';
import { DraggableListContext } from './utils/store-context';

import styles from './draggable-list-selector.module.scss';

interface SelectionChangeEvent {
	selectedItems?: UserDraggableItem[];
	unselectedItems?: UserDraggableItem[];
}

interface Props {
	className?: string;
	collapseClassName?: string;
	translations: DraggableListSelectorTranslations;
	selectedValues: DraggableItem[];
	unselectedValues: DraggableItem[];
	getDefault: () => { selectedValues: DraggableItem[]; unselectedValues: DraggableItem[] };
	onApply: (selectedValues: DraggableItem[]) => void;
	onCancel?: () => void;
	withSearch?: boolean;
	changeImmediate?: boolean;
}

const listTypes = [ListsType.SELECTED, ListsType.NON_SELECTED];

export const DraggableListSelector = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		translations,
		className,
		collapseClassName,
		selectedValues,
		unselectedValues,
		getDefault,
		withSearch,
		onApply,
		onCancel,
		changeImmediate
	} = props;
	const {
		title,
		searchPlaceholder,
		selected: selectedText,
		nonSelected: nonSelectedText,
		empty: emptyText,
		applyButton,
		cancelButton,
		defaultButton
	} = translations;

	const dragPortal = useRef<HTMLDivElement>(null);
	const [searchString, setSearchString] = useState('');
	const [activeKeys, setActiveKeys] = useState<ListsType[]>([ListsType.SELECTED, ListsType.NON_SELECTED]);
	const [glowCollapse, setGlowCollapse] = useState<ListsType | undefined>(undefined);

	const [selectedList, setSelectedList] = useState<UserDraggableItem[]>(selectedValues);
	const [unselectedList, setUnselectedList] = useState<UserDraggableItem[]>(unselectedValues);

	const [isDraggedRequired, setIsDraggedRequired] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	const handleSelectionChange = ({ selectedItems, unselectedItems }: SelectionChangeEvent) => {
		if (changeImmediate && selectedItems) {
			onApply(mapListForApply(selectedItems));
		}

		if (selectedItems) {
			setSelectedList(selectedItems);
			setIsDirty(checkDirty(selectedItems, selectedValues));
		}
		unselectedItems && setUnselectedList(unselectedItems);
	};

	const onDragEnd = (dropResult: DropResult) => {
		const { draggableId, source: droppableSource, destination: droppableDestination } = dropResult;

		isDraggedRequired && setIsDraggedRequired(false);

		if (droppableDestination) {
			if (droppableSource.droppableId === droppableDestination.droppableId) {
				const list = droppableSource.droppableId === ListsType.SELECTED ? selectedList : unselectedList;
				const items = reorder(
					list,
					list.findIndex(item => item.id === draggableId),
					droppableDestination.index
				);

				let selection: SelectionChangeEvent;
				if (droppableSource.droppableId === ListsType.NON_SELECTED) {
					selection = { unselectedItems: items };
				} else {
					selection = { selectedItems: items };
				}
				handleSelectionChange(selection);
			} else {
				const sourceList = droppableSource.droppableId === ListsType.SELECTED ? selectedList : unselectedList;
				const destinationList =
					droppableDestination.droppableId === ListsType.SELECTED ? selectedList : unselectedList;
				const result = move({
					draggableId,
					sourceList,
					destinationList,
					droppableSource,
					droppableDestination
				});

				handleSelectionChange({
					selectedItems: result[ListsType.SELECTED],
					unselectedItems: result[ListsType.NON_SELECTED]
				});
			}
		}
	};

	const onDragStart = ({ draggableId, source }: DragStart) => {
		if (source.droppableId === ListsType.SELECTED && selectedList.find(item => item.id === draggableId)?.required) {
			setIsDraggedRequired(true);
		}
	};

	const makeCollapseGlow = () => {
		if (activeKeys.length) {
			const collapsed = listTypes.filter(key => !activeKeys.includes(key));

			if (collapsed.includes(ListsType.SELECTED)) {
				setGlowCollapse(ListsType.SELECTED);
			}

			if (collapsed.includes(ListsType.NON_SELECTED)) {
				setGlowCollapse(ListsType.NON_SELECTED);
			}

			setTimeout(() => {
				setGlowCollapse(undefined);
			}, 500);
		}
	};

	const onSelectCheckBox = (index: number, listType: ListsType, id: string) => {
		let droppableSource: DraggableLocation, droppableDestination: DraggableLocation;
		let sourceList: UserDraggableItem[], destinationList: UserDraggableItem[];

		if (listType === ListsType.SELECTED) {
			sourceList = [...selectedList];
			destinationList = [...unselectedList];
			droppableSource = { droppableId: ListsType.SELECTED, index };
			droppableDestination = { droppableId: ListsType.NON_SELECTED, index: 0 };
		} else {
			sourceList = [...unselectedList];
			destinationList = [...selectedList];
			droppableSource = { droppableId: ListsType.NON_SELECTED, index };
			droppableDestination = { droppableId: ListsType.SELECTED, index: destinationList.length };
		}

		// waiting for the fadeOut animation to end
		setTimeout(() => {
			const result = move({
				draggableId: id,
				sourceList,
				destinationList,
				droppableSource,
				droppableDestination,
				withFadeIn: true
			});

			handleSelectionChange({
				selectedItems: result[ListsType.SELECTED],
				unselectedItems: result[ListsType.NON_SELECTED]
			});
			makeCollapseGlow();
		}, 350);
	};

	const defaultRequest = () => {
		const defaultLists = getDefault();

		handleSelectionChange({
			selectedItems: defaultLists.selectedValues,
			unselectedItems: defaultLists.unselectedValues
		});
	};

	return (
		<DraggableListContext.Provider value={{ dragPortal }}>
			<div className={classNames(className, styles.draggableListSelector)} ref={ref}>
				<Header title={title} onDefault={defaultRequest} defaultText={defaultButton} />
				{withSearch ? (
					<SearchBar value={searchString} setSearchString={setSearchString} placeholder={searchPlaceholder} />
				) : (
					<div className={styles.divider} />
				)}

				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
					<Collapse
						wrapClassName={classNames(collapseClassName, styles.collapse)}
						ghost
						defaultActiveKey={activeKeys}
						onChange={keys => {
							setActiveKeys(keys);
						}}
						expandIcon={({ isActive }) => (
							<BringgIcon
								className={styles.collapseArrow}
								iconName={BringgFontIcons.ChevronRight}
								rotate90={isActive}
							/>
						)}
						removePadding
					>
						<Collapse.Panel
							key={ListsType.SELECTED}
							header={selectedText}
							className={classNames(styles.collapsePanel, {
								[styles.glow]: glowCollapse === ListsType.SELECTED
							})}
							data-test-id={'selected-panel'}
							headerClassName={styles.collapseHeader}
						>
							<DropContent
								droppableId={ListsType.SELECTED}
								list={selectedList}
								searchString={searchString}
								onItemCheckboxChange={onSelectCheckBox}
								emptyText={emptyText}
							/>
						</Collapse.Panel>
						<Collapse.Panel
							key={ListsType.NON_SELECTED}
							header={nonSelectedText}
							className={classNames(styles.collapsePanel, {
								[styles.glow]: glowCollapse === ListsType.NON_SELECTED
							})}
							data-test-id={'non-selected-panel'}
							headerClassName={classNames(styles.collapseHeader)}
						>
							<DropContent
								droppableId={ListsType.NON_SELECTED}
								isDropDisabled={isDraggedRequired}
								list={unselectedList}
								searchString={searchString}
								onItemCheckboxChange={onSelectCheckBox}
								emptyText={emptyText}
							/>
						</Collapse.Panel>
					</Collapse>
				</DragDropContext>
				{!changeImmediate && (
					<Footer
						isDirty={isDirty}
						onApply={() => {
							onApply(mapListForApply(selectedList));
						}}
						onCancel={() => onCancel?.()}
						translations={{ apply: applyButton, cancel: cancelButton }}
					/>
				)}
			</div>
			{ReactDOM.createPortal(
				<div id="draggable-selector-portal" ref={dragPortal} className={styles.draggableListPortal} />,
				document.body
			)}
		</DraggableListContext.Provider>
	);
});
