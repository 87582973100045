import { FC } from 'react';

import { Button, Select, Space, Spinner } from '@bringg/react-components';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Count from './count';
import { PaginationProps } from './types';

const FIRST_PAGE = 1;

export const Pagination = ({ limit, page, filters, loading, nextPage, onChange, onSelect }: PaginationProps) => {
	const { t } = useTranslation();
	const isFirstPage = page === FIRST_PAGE;

	const { isLoading, data, error } = useQuery<{ count: number }>(
		['historyTasksMetadata', filters],
		async (): Promise<{ count: number }> => {
			if (filters.late) {
				const converted = filters.late.map((val: number | boolean) => !!val);

				filters.late = converted;
			}

			return getRootEnv().dashboardSdk.sdk.tasks.getHistoryTasksMetadata(filters);
		},
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			enabled: true
		}
	);

	const RenderCountComponent: FC = () => {
		if (isLoading) {
			return <Spinner size="small" />;
		}

		if (error) {
			return <div>{t('HISTORY_PAGE.COUNT_LOAD_FAILED')}</div>;
		}

		return (
			<Count
				count={data?.count}
				limit={limit}
				page={page}
				isFirstPage={isFirstPage}
				outOfText={t('HISTORY_PAGE.COUNT_OUT_OF')}
			/>
		);
	};

	return (
		<Space style={{ display: 'flex', justifyContent: 'start' }}>
			<Select
				value={limit}
				options={[
					{ id: 25, name: t('PAGINATION.ITEMS_PER_PAGE', { size: 25 }) },
					{ id: 50, name: t('PAGINATION.ITEMS_PER_PAGE', { size: 50 }) },
					{ id: 100, name: t('PAGINATION.ITEMS_PER_PAGE', { size: 100 }) }
				]}
				onSelect={onSelect}
			/>
			<div className="bringg-vertical-divider" />
			<RenderCountComponent />
			<div className="bringg-vertical-divider" />
			<Button
				disabled={loading || isFirstPage}
				onClick={() => {
					if (page > 1) onChange((p: number) => p - 1);
				}}
			>
				{t('PAGINATION.PREVIOUS')}
			</Button>
			<Button disabled={loading || nextPage} onClick={() => onChange((p: number) => p + 1)}>
				{t('PAGINATION.NEXT')}
			</Button>
		</Space>
	);
};

export default Pagination;
