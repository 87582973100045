import { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { ObjectKey, UpdateURLStrategy } from './types';
import { cleanFilterObject, isFilterObjectAndSearchParamsEquals, searchParamsToObject, updateLocation } from './utils';

enum OriginOfParamChange {
	HookConsumer = 'hook',
	LocationChange = 'location'
}

interface Options<T extends Record<string, unknown>> {
	trackedKeys?: (keyof T)[];
	// if false, must set `keysToTrack`
	resetUntrackedParamsOnChange?: boolean;
	updateURLStrategy?: UpdateURLStrategy;
	defaultValue?: T;
	defaultOptions?: { updateSearch: false } | { updateSearch: true; resetOthersParams?: boolean };
	ignoreLocationChanges?: boolean;
}

// On user's filter object change we need to update the query
// On first init we get the filter object from the query
const useFilterQuery = <T extends Record<string, unknown>>({
	trackedKeys = undefined,
	defaultValue,
	resetUntrackedParamsOnChange = false,
	updateURLStrategy = UpdateURLStrategy.ADD,
	defaultOptions,
	ignoreLocationChanges = false
}: Options<T> = {}): {
	filterObject: T;
	setFilterObject: (valueOrSetter: T | ((prev: T) => T)) => void;
	initialFilterObjectFromQuery: T;
} => {
	const location = useLocation();
	const history = useHistory();

	const [rawFilterQueryValue, setRawFilterQueryValue] = useState<T>(() => {
		const valueFromQuery = cleanFilterObject(
			searchParamsToObject(location.search, trackedKeys as ObjectKey[])
		) as T;

		// eslint-disable-next-line eqeqeq
		if (valueFromQuery != undefined && Object.keys(valueFromQuery).length) {
			return valueFromQuery;
		}

		// eslint-disable-next-line eqeqeq
		if (defaultValue == undefined) {
			return {} as T;
		}

		if (!defaultOptions?.updateSearch) {
			return {} as T;
		}

		updateLocation({
			trackedKeys,
			newFilterQueryObject: defaultValue,
			resetOtherParams: defaultOptions.resetOthersParams,

			// Always replacing as we just set the default value to appear in the URL
			updateURLStrategy: UpdateURLStrategy.REPLACE,
			history,
			location
		});

		return defaultValue;
	});

	const initialFilterObjectFromQuery = useRef(rawFilterQueryValue);

	const [filterQueryWithMetadata, setFilterQueryWithMetadata] = useState<{
		value: T;
		originOfChange: OriginOfParamChange;
	}>({
		value: rawFilterQueryValue,
		originOfChange: OriginOfParamChange.LocationChange
	});

	const updateFilterQuery = useCallback(newValueOrCbWithPrev => {
		setFilterQueryWithMetadata(prev => {
			let newValue = newValueOrCbWithPrev;
			if (typeof newValueOrCbWithPrev === 'function') {
				newValue = newValueOrCbWithPrev(prev.value);
			}

			return { value: cleanFilterObject(newValue) as T, originOfChange: OriginOfParamChange.HookConsumer };
		});
	}, []);

	useEffect(() => {
		if (ignoreLocationChanges && filterQueryWithMetadata.originOfChange === OriginOfParamChange.LocationChange) {
			return;
		}

		// updating the origin to location change so if the url later change it won't be treated as hook change
		if (filterQueryWithMetadata.originOfChange === OriginOfParamChange.HookConsumer) {
			setFilterQueryWithMetadata({
				value: filterQueryWithMetadata.value,
				originOfChange: OriginOfParamChange.LocationChange
			});
		}

		// If the current filter query is the same as in the route don't update
		if (isFilterObjectAndSearchParamsEquals(filterQueryWithMetadata.value, location.search, trackedKeys)) {
			return;
		}

		if (filterQueryWithMetadata.originOfChange === OriginOfParamChange.LocationChange) {
			setFilterQueryWithMetadata({
				value: cleanFilterObject(searchParamsToObject(location.search, trackedKeys as ObjectKey[])) as T,
				originOfChange: OriginOfParamChange.LocationChange
			});

			// If this changed due to location change we don't want to update the location again
			return;
		}

		updateLocation({
			resetOtherParams: resetUntrackedParamsOnChange,
			trackedKeys,
			history,
			location,
			newFilterQueryObject: filterQueryWithMetadata.value,
			updateURLStrategy
		});
	}, [
		history,
		location,
		filterQueryWithMetadata,
		updateURLStrategy,
		resetUntrackedParamsOnChange,
		trackedKeys,
		ignoreLocationChanges
	]);

	useEffect(() => {
		setRawFilterQueryValue(filterQueryWithMetadata.value);
	}, [filterQueryWithMetadata]);

	return {
		filterObject: rawFilterQueryValue,
		setFilterObject: updateFilterQuery,
		initialFilterObjectFromQuery: initialFilterObjectFromQuery.current
	};
};

export default useFilterQuery;
