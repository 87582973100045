import React, { useMemo, useCallback, useEffect, useState } from 'react';

import isArray from 'lodash/isArray';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import {
	Popover,
	Spin,
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Row,
	Tag,
	Typography,
	Select
} from 'antd';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { EcoButton, FavoriteButton } from './components';
import { useStores } from '../../recipes';

interface FormFilterProps {
	name: string;
	translateKey?: string;
	form: FormInstance;
	onFilterOptions: (value: string) => Promise<any>;
}

const FormFilter = ({ name, translateKey, form, onFilterOptions }: FormFilterProps) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();
	const [isLoading, setIsLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [values, setValues] = useState([]);
	const [extraValues, setExtraValues] = useState([]);
	const [prevState, setPrevState] = useState({});
	const filterName = t(`DELIVERY_PROVIDER_CATALOG.FILTERS.${name.toUpperCase()}`);
	const getFilterItem = item => (translateKey ? t(`${translateKey}.${item.toUpperCase()}`) : item);

	const isUSMerchant = useMemo(() => {
		return merchantConfigurationsStore.merchantCountry === 'us';
	}, [merchantConfigurationsStore]);

	const fetchValues = async () => {
		setIsLoading(true);

		setValues(await onFilterOptions(name));

		if (name === 'service_levels') {
			setExtraValues(await onFilterOptions('on_demand_time'));
		}

		setIsLoading(false);
	};

	const handleOnDemand = () => {
		const formValues = form.getFieldsValue();

		const isOnDemandSelected = formValues.service_levels?.includes('On Demand');
		const isOnDemandTimeSelected = Boolean(formValues.on_demand_time);

		if (!isOnDemandSelected && isOnDemandTimeSelected) {
			form.setFieldsValue({ on_demand_time: undefined });
		}
	};

	const handleCancel = () => {
		setVisible(false);
		form.setFieldsValue(prevState);
		handleOnDemand();
	};

	const handleVisibleChange = async isVisible => {
		if (isVisible) {
			setVisible(true);
			await fetchValues();
			setPrevState(form.getFieldsValue([name]));
		} else {
			handleCancel();
		}
	};

	const handleDone = () => {
		setVisible(false);
		handleOnDemand();
		form.submit();
	};

	const getCount = formName => {
		const value = form.getFieldValue(formName);

		if (Array.isArray(value)) {
			return value.length;
		}

		return Number(Boolean(value));
	};

	const searchCountryHandler = (input, option) => {
		return option.key.toLowerCase().includes(input.toLowerCase());
	};

	const getContent = () => {
		if (name === 'countries') {
			return (
				<Col span={24}>
					<Form.Item name={name} noStyle>
						<Select
							filterOption={searchCountryHandler}
							className="delivery-catalog-filter-bar-small-filter"
							placeholder={t('DELIVERY_PROVIDER_CATALOG.FILTERS.SEARCH_PLACEHOLDER')}
							mode="multiple"
							showSearch
						>
							{values.map(item => (
								<Select.Option value={item.value} key={item.label}>
									{getFilterItem(item.label)}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			);
		}

		if (name === 'max_delivery_radius') {
			return (
				<Col span={24}>
					<Form.Item name={name} noStyle>
						<InputNumber
							className="delivery-catalog-filter-bar-small-filter"
							placeholder={
								isUSMerchant
									? t('DELIVERY_PROVIDER_CATALOG.FILTERS.INSERT_MILES_PLACEHOLDER')
									: t('DELIVERY_PROVIDER_CATALOG.FILTERS.INSERT_KILOMETERS_PLACEHOLDER')
							}
							min={1}
						/>
					</Form.Item>
				</Col>
			);
		}

		if (name === 'service_levels') {
			return (
				<Col span={24}>
					<Form.Item name={name} noStyle>
						<Checkbox.Group>
							{values.map(item => (
								<div key={item}>
									<Checkbox value={item}>{item}</Checkbox>
								</div>
							))}
						</Checkbox.Group>
					</Form.Item>
					<Form.Item noStyle shouldUpdate>
						{() => {
							// eslint-disable-next-line
							const isDisabled = !form.getFieldValue('service_levels')?.includes('On Demand');

							return (
								<Form.Item name="on_demand_time" noStyle>
									<Select
										disabled={isDisabled}
										placeholder={t(
											'DELIVERY_PROVIDER_CATALOG.FILTERS.ON_DEMAND_WINDOW_PLACEHOLDER'
										)}
										style={{ width: '100%', marginTop: '8px' }}
									>
										{extraValues.map(item => (
											<Select.Option value={item} key={item}>
												{t(
													`DELIVERY_PROVIDER_CATALOG.FILTERS_DATA.ON_DEMAND_TIME.${item.toUpperCase()}`
												)}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			);
		}

		return (
			<Col span={24}>
				<Form.Item name={name} noStyle>
					<Checkbox.Group>
						{values.map(item => (
							<div key={item}>
								<Checkbox value={item}>{getFilterItem(item)}</Checkbox>
							</div>
						))}
					</Checkbox.Group>
				</Form.Item>
			</Col>
		);
	};

	return (
		<Popover
			open={visible}
			onOpenChange={handleVisibleChange}
			trigger="click"
			placement="bottom"
			content={
				<Spin spinning={isLoading}>
					<div className="delivery-catalog-filter-bar-content">
						<Typography.Title level={5} style={{ color: '#4a5568' }}>
							{filterName}
						</Typography.Title>

						<Row gutter={[0, 24]}>
							{getContent()}

							<Col span={12}>
								<Button block type="link" onClick={handleCancel}>
									{t('DELIVERY_PROVIDER_CATALOG.FILTERS.CANCEL')}
								</Button>
							</Col>

							<Col span={12}>
								<Button block type="primary" onClick={handleDone}>
									{t('DELIVERY_PROVIDER_CATALOG.FILTERS.DONE')}
								</Button>
							</Col>
						</Row>
					</div>
				</Spin>
			}
		>
			<Tag className="delivery-catalog-filter-bar-tag">
				<Form.Item shouldUpdate noStyle>
					{() =>
						getCount(name) ? (
							<>
								<strong>{getCount(name)}</strong> {filterName}
							</>
						) : (
							filterName
						)
					}
				</Form.Item>
			</Tag>
		</Popover>
	);
};

interface DeliveryCatalogFilterBarProps {
	onFilter: (fieldValues: Record<string, unknown>) => void;
	onFilterOptions: (value: string) => Promise<string[]>;
	getSavedFilters: () => Record<string, unknown>;
}

const DeliveryCatalogFilterBar = ({ onFilter, onFilterOptions, getSavedFilters }: DeliveryCatalogFilterBarProps) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const initialValues = {};

	useEffect(() => {
		form.setFieldsValue({ ...getSavedFilters() });
	}, []);

	const handleFinish = () => {
		onFilter(form.getFieldsValue(true));
	};

	const handleReset = () => {
		form.resetFields();
		onFilter(form.getFieldsValue(true));
	};

	const handleSearch = useCallback(debounce(handleFinish, 500, { trailing: true }), [handleFinish]);

	const isResetActive = () => {
		const values = form.getFieldsValue(true);

		return Object.values(values).some(value => (isArray(value) ? value.length : value));
	};

	// TODO: find a fay to refactor to native form element like checkbox
	const isEcoFriendlyFilterActive = () => form.getFieldValue('eco_friendly');
	const isFavoritesFilterActive = () => form.getFieldValue('favorites');

	const onEcoFriendlyFilterChanged = () => {
		form.setFieldsValue({ eco_friendly: isEcoFriendlyFilterActive() ? undefined : true });
		form.submit();
	};

	const onFavoritesFilterChanged = () => {
		form.setFieldsValue({ favorites: isFavoritesFilterActive() ? undefined : true });
		form.submit();
	};

	return (
		<div className="delivery-catalog-filter-bar">
			<Form form={form} initialValues={initialValues} onFinish={handleFinish}>
				<Row justify="space-between" wrap={false}>
					<Col flex="auto">
						<Row align="middle" gutter={24} wrap={false}>
							<Col>
								<Form.Item name="name" noStyle>
									<Input
										placeholder="Search"
										style={{ width: 164 }}
										suffix={<BringgIcon iconName={BringgFontIcons.Search} />}
										onChange={handleSearch}
									/>
								</Form.Item>
							</Col>

							<Col flex="auto" className="delivery-catalog-filter-bar-tags">
								<Row wrap={false} style={{ overflow: 'auto' }}>
									<FormFilter
										name="countries"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="delivery_types"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="service_levels"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="max_delivery_radius"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="white_gloves"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="shipping_origins"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="age_restrictions"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="technical_functionalities"
										translateKey="DELIVERY_PROVIDER_CATALOG.FILTERS_DATA.TECHNICAL_FUNCTIONALITIES"
										form={form}
										onFilterOptions={onFilterOptions}
									/>
									<FormFilter
										name="vehicle_types"
										translateKey={null}
										form={form}
										onFilterOptions={onFilterOptions}
									/>
								</Row>
							</Col>

							<Col>
								{/* TODO: find a fay to refactor to native form element like checkbox */}
								<Row wrap={false} className="filter-buttons">
									<Form.Item shouldUpdate>
										{() => (
											<Form.Item name="eco_friendly" noStyle>
												<EcoButton
													title={t('DELIVERY_PROVIDER_CATALOG.DETAILS.ECO_FRIENDLY')}
													isActive={isEcoFriendlyFilterActive()}
													onClick={onEcoFriendlyFilterChanged}
												/>
											</Form.Item>
										)}
									</Form.Item>

									<Form.Item shouldUpdate>
										{() => (
											<Form.Item name="favorites" noStyle>
												<FavoriteButton
													isActive={isFavoritesFilterActive()}
													onClick={onFavoritesFilterChanged}
												/>
											</Form.Item>
										)}
									</Form.Item>
								</Row>
							</Col>
						</Row>
					</Col>

					<Col flex="none">
						<Row>
							<Col>
								<Form.Item noStyle shouldUpdate>
									{() => (
										<Button type="link" disabled={!isResetActive()} onClick={handleReset}>
											{t('DELIVERY_PROVIDER_CATALOG.FILTERS.RESET_FILTER')}
										</Button>
									)}
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default DeliveryCatalogFilterBar;
