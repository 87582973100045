import React, { useEffect, useState, useMemo, useCallback } from 'react';

import {
	Button,
	FilterBar2,
	FilterBar2ListItem,
	FilterBarTranslations,
	Tag,
	ConfirmModal,
	FilterBar2Value,
	DropdownMenu,
	DropdownMenuItem,
	TagType
} from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import TableWrapper from '@bringg/react-components/dist/components/table';
import _ from 'lodash';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import moment from 'moment';
import { OptimizationItem } from '@bringg/types';
import { TriggerType } from '@bringg-frontend/bringg-map';
import { useDateTimeFormat } from '@bringg-frontend/hooks';

import { mapTeamToFilter } from 'bringg-web';
import { useStores } from 'bringg-web/recipes';
import OptimizationList from '../../images/optimization-list.svg';

import './configurations-list.scss';

interface CreateDeleteHelpBarProps {
	onCreate: () => void;
	onDelete: (ids: number[]) => Promise<void>;
	defaultOptimization: boolean;
}

interface ConfigurationsListProps {
	configurations: OptimizationItem[];
	onCreate: () => void;
	onEditDropdown: (config: OptimizationItem) => void;
	onDeleteDropdown: (config: OptimizationItem) => Promise<void>;
	onDuplicateDropdown: (config: OptimizationItem) => Promise<void>;
	onBatchDelete: (configs: number[]) => Promise<void>;
}

const nameSorter = (path: string) => (a: OptimizationItem, b: OptimizationItem) => {
	if (a.defaultConfiguration || b.defaultConfiguration) {
		return 0;
	}

	return a[path].localeCompare(b[path]);
};

interface EmptyTableDataInput {
	onCreate: () => void;
	t: typeof useTranslation;
	defaultOptimization: boolean;
}

const EmptyTableData = ({ onCreate, defaultOptimization, t }: EmptyTableDataInput) => {
	const createMerchantText = defaultOptimization
		? 'OPTIMIZATION_CONFIGURATIONS.TABLE.CREATE_MERCHANT_LEVEL_POLICY'
		: 'OPTIMIZATION_CONFIGURATIONS.TABLE.CREATE_TEAM_LEVEL_POLICY';

	return (
		<div className="route-optimization-settings-empty-table">
			<img src={OptimizationList} alt="optimization-list" />
			<div className="route-optimization-create-new-description">
				{t('OPTIMIZATION_CONFIGURATIONS.TABLE.NO_OPTIMIZATIONS')}
			</div>
			<Button onClick={onCreate} type="link" size="large" className="route-optimization-create-new">
				{t(createMerchantText)}
			</Button>
		</div>
	);
};

const ConfigurationsList = ({
	configurations,
	onCreate,
	onEditDropdown,
	onDeleteDropdown,
	onDuplicateDropdown,
	onBatchDelete
}: ConfigurationsListProps) => {
	const { t } = useTranslation();
	const [dataConf, setDataConf] = useState(configurations);
	const dateTimeFormat = useDateTimeFormat();

	useEffect(() => {
		setDataConf(configurations);
	}, [configurations]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys as number[]);
	};

	const getCheckboxProps = (record: OptimizationItem) => ({
		disabled: record.defaultConfiguration,
		name: record.configurationName
	});

	const { teamsStore } = useStores();

	const teamOptions = useMemo<FilterBar2Value[]>(
		() => mapTeamToFilter(teamsStore.all, teamsStore),
		[teamsStore, teamsStore.all]
	);

	const filterItemsList: FilterBar2ListItem[] = [
		{
			name: 'textSearch',
			title: t('OPTIMIZATION_CONFIGURATIONS.LIST.SEARCH'),
			type: FilterBar2.TYPES.SEARCH,
			placeholder: t('OPTIMIZATION_CONFIGURATIONS.LIST.SEARCH')
		},
		{
			name: 'teams',
			title: t(`OPTIMIZATION_CONFIGURATIONS.LIST.TEAMS_FILTER`),
			type: FilterBar2.TYPES.TEAMS,
			applyFilterOnValueChange: true,
			multiselect: true,
			values: teamOptions
		}
	];

	const columns = [
		{
			title: t('OPTIMIZATION_CONFIGURATIONS.LIST.OPTIMIZATION_NAME'),
			dataIndex: 'configurationName',
			className: 'route-optimization-configuration-name-column',
			width: '40%',
			render: (name, record: OptimizationItem) => {
				if (record.defaultConfiguration) {
					return (
						<>
							{name}
							<Tag type={TagType.info} className="route-optimization-default-merchant-tag">
								{t('OPTIMIZATION_CONFIGURATIONS.LIST.MERCHANT')}
							</Tag>
						</>
					);
				}
				return name;
			},
			sorter: nameSorter('configurationName')
		},
		{
			title: 'Teams',
			dataIndex: 'teams',
			render: (teams, record: OptimizationItem) => {
				if (record.defaultConfiguration) {
					return <hr className="route-optimization-no-teams-merchant-level" />;
				}

				if (teams.length === 1) {
					return <>{teamsStore.teams.get(record.teams[0])?.name}</>;
				}

				return (
					<Tag className="route-optimization-teams-count">
						<span>
							{teams.length} {t('OPTIMIZATION_CONFIGURATIONS.LIST.TEAMS')}
						</span>
					</Tag>
				);
			},
			sorter: (a: OptimizationItem, b: OptimizationItem) => {
				if (a.defaultConfiguration || b.defaultConfiguration) {
					return 0;
				}

				return a.teams.length - b.teams.length;
			}
		},
		{
			title: t('OPTIMIZATION_CONFIGURATIONS.LIST.CREATED_BY'),
			dataIndex: 'createdBy',
			sorter: nameSorter('createdBy')
		},
		{
			title: t('OPTIMIZATION_CONFIGURATIONS.LIST.CREATED_AT'),
			dataIndex: 'createdAt',
			render: (_, record) => moment(record.createdAt).format(dateTimeFormat),
			sorter: nameSorter('createdAt')
		},
		{
			title: t('OPTIMIZATION_CONFIGURATIONS.LIST.ACTION'),
			dataIndex: 'action',
			render: (_, record: OptimizationItem) => {
				if (record.defaultConfiguration) {
					return (
						<Button
							className="route-optimization-default-edit"
							type="regular-link"
							icon={<BringgIcon iconName={BringgFontIcons.Pencil} />}
							onClick={() => onEditDropdown(record)}
						>
							{t('OPTIMIZATION_CONFIGURATIONS.LIST.EDIT')}
						</Button>
					);
				}

				const items: DropdownMenuItem[] = [
					{
						key: 'edit',
						label: t('OPTIMIZATION_CONFIGURATIONS.LIST.EDIT'),
						icon: <BringgIcon iconName={BringgFontIcons.Pencil} />,
						onClick: () => onEditDropdown(record)
					}
				];

				if (!record.defaultConfiguration) {
					items.push(
						{
							key: 'duplicate',
							label: t('OPTIMIZATION_CONFIGURATIONS.LIST.DUPLICATE'),
							icon: <BringgIcon iconName={BringgFontIcons.Copy} />,
							onClick: async () => onDuplicateDropdown(record)
						},
						{
							key: 'delete',
							label: t('OPTIMIZATION_CONFIGURATIONS.LIST.DELETE'),
							dataTestId: 'deleteDropdown',
							icon: <BringgIcon iconName={BringgFontIcons.Trash} />,
							onClick: () => {
								ConfirmModal({
									title: `Delete ${record.configurationName} configuration?`,
									content: t('OPTIMIZATION_CONFIGURATIONS.LIST.DELETE_PROMPT'),
									onOk: async () => onDeleteDropdown(record)
								});
							}
						}
					);
				}

				return (
					<DropdownMenu
						items={items}
						trigger={[TriggerType.CLICK]}
						overlayClassName="actions-popover"
						noArrow={false}
					>
						<Button
							className="hidden-action-item"
							shape="circle"
							type="link"
							icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />}
							data-test-id="route-optimization-menu-action"
						/>
					</DropdownMenu>
				);
			}
		}
	];

	const handleFilters = useCallback(
		filters => {
			const { textSearch, teams } = filters;
			let filteredConfigurations = [...configurations];

			if (!_.isNil(textSearch)) {
				filteredConfigurations = filteredConfigurations.filter(config =>
					['configurationName', 'createdBy'].some(key =>
						String(config[key]).toLowerCase().includes(textSearch?.toLowerCase())
					)
				);
			}

			if (teams?.length) {
				filteredConfigurations = filteredConfigurations.filter(config => {
					return config.teams.some(team => teams.includes(team));
				});
			}

			setDataConf(filteredConfigurations);
		},
		[configurations]
	);

	const CreateDeleteHelp = ({ onDelete, onCreate, defaultOptimization }: CreateDeleteHelpBarProps) => {
		const handleDelete = () =>
			ConfirmModal({
				title: `Delete ${selectedRowKeys.length} configuration(s)?`,
				content: t('OPTIMIZATION_CONFIGURATIONS.LIST.DELETE_PROMPT'),
				onOk: async () => onDelete(selectedRowKeys)
			});

		return (
			<div className="route-optimization-list-top-bar">
				<div className="route-optimization-generic-actions">
					<Button
						className="configuration-list-delete-btn"
						data-test-id="conf-list-delete-btn"
						disabled={!selectedRowKeys.length}
						icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
						onClick={handleDelete}
					>
						{t('OPTIMIZATION_CONFIGURATIONS.LIST.DELETE')}
					</Button>

					<a
						href="https://help.bringg.com/docs/about-route-optimization-in-bringg"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Button
							className="configuration-list-help-btn"
							data-test-id="conf-list-help-btn"
							icon={<BringgIcon iconName={BringgFontIcons.Help} />}
							target="_blank"
						>
							{t('OPTIMIZATION_CONFIGURATIONS.LIST.HELP')}
						</Button>
					</a>
				</div>
				{!defaultOptimization && (
					<div className="route-optimization-create-override">
						<Button
							type="primary"
							className="configuration-list-create-btn"
							data-test-id="conf-list-create-btn"
							onClick={onCreate}
						>
							{t('OPTIMIZATION_CONFIGURATIONS.LIST.CREATE_OVERRIDE')}
						</Button>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="route-optimization-configurations-list">
			<FilterBar2
				list={filterItemsList}
				onFilter={handleFilters}
				className="route-optimization-filterbar"
				translations={
					{
						reset: t('OPTIMIZATION_CONFIGURATIONS.LIST.RESET_FILTERS'),
						search: t('OPTIMIZATION_CONFIGURATIONS.LIST.SEARCH'),
						all: '',
						teams: t('FILTER_BAR.PARENT_TEAMS'),
						subTeams: t('FILTER_BAR.TEAMS')
					} as unknown as FilterBarTranslations
				}
			/>
			<CreateDeleteHelp
				onCreate={onCreate}
				onDelete={onBatchDelete}
				defaultOptimization={!configurations.length}
			/>
			<TableWrapper
				rowKey={({ id }) => id}
				columns={columns}
				dataSource={dataConf}
				className="route-optimization-settings-table"
				locale={{
					emptyText: EmptyTableData({ onCreate, t, defaultOptimization: !configurations.length })
				}}
				bordered
				rowSelection={{ type: 'checkbox', selectedRowKeys, onChange: onSelectChange, getCheckboxProps }}
			/>
		</div>
	);
};

export default ConfigurationsList;
