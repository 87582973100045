import React, { useCallback, useEffect, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import FleetsListComponent from 'bringg-web/features/fleets-configuration/fleets-list/fleets-list';
import { useStores } from 'bringg-web/recipes';
import { Portal } from '../index';
import DeliveryCatalogConnect from './delivery-catalog-connect';

export const DeliveryCatalogConnectPortal = () => {
	const { providerId } = useParams();
	const history = useHistory();

	const { deliveryCatalogStore, fleetsConfigurationStore, merchantConfigurationsStore } = useStores();
	const isCatalogConnectEnabled = merchantConfigurationsStore.configuration.delivery_hub_main_menu_ui;

	useEffect(() => {
		if (!isCatalogConnectEnabled) {
			fleetsConfigurationStore.load();
		}
	}, [isCatalogConnectEnabled]);

	const handleOpenDeliveryCatalog = useCallback(() => {
		history.push(`/delivery-catalog`);
	}, [history]);

	const deliveryProvider = useMemo(() => {
		return deliveryCatalogStore.deliveryProviders.get(Number(providerId));
	}, [deliveryCatalogStore.deliveryProviders]);

	const fleet = useMemo(() => {
		if (deliveryProvider) {
			return Object.values(fleetsConfigurationStore.fleets).find(({ name }) => name === deliveryProvider.name);
		}

		return null;
	}, [fleetsConfigurationStore.fleets, deliveryProvider]);

	return (
		<Portal
			element={
				isCatalogConnectEnabled ? (
					<DeliveryCatalogConnect
						deliveryProvider={deliveryProvider}
						onOpenDeliveryCatalog={handleOpenDeliveryCatalog}
						className="singleton"
					/>
				) : (
					<FleetsListComponent fleet={fleet} />
				)
			}
			nodeId="delivery-catalog-connect-portal"
		/>
	);
};
