import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import moment from 'moment-timezone';
import { DeliveryBlockTemplate as DeliveryBlockTemplateType, DeliveryBlockTemplateSchedule, Team } from '@bringg/types';
import { Frequency } from 'rrule';
import { dateUtils } from '@bringg-frontend/utils';

import DeliveryBlockTemplateTitles from 'bringg-web/features/delivery-blocks-v2/settings/template/main/titles/delivery-block-template-titles';
import { DeliveryBlockTime } from 'bringg-web/features/delivery-blocks-v2/new-modal/delivery-block-time/delivery-block-time';
import { BreaksPanel } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/breaks-panel';
import {
	DEFAULT_DIFFERENCE_BETWEEN_DATES_IN_HOURS,
	PANEL_KEYS
} from 'bringg-web/features/delivery-blocks-v2/new-modal/delivery-block-modal';
import { RechargesPanel } from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-panel/recharges-panel';
import { RecurrencePanel } from 'bringg-web/features/delivery-blocks-v2/new-modal/recurrence/recurrence-panel';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { useStores } from 'bringg-web/recipes';
import { breakEditor } from 'bringg-web/features/delivery-blocks-v2/services/break-editor-service';
import { rechargeEditor } from 'bringg-web/features/delivery-blocks-v2/services/recharge-editor-service';
import { templateValidator } from 'bringg-web/features/delivery-blocks-v2/validators/template-validator';
import { deliveryBlockBaseEditor } from 'bringg-web/features/delivery-blocks-v2/services/delivery-block-base-editor-service';
import TeamsPanel from 'bringg-web/features/delivery-blocks-v2/new-modal/teams/teams-panel/teams-panel';
import {
	allRecurringFrequencies,
	RECURRING_NO_REPEAT_FREQUENCY
} from 'bringg-web/components/recurring/recurring.consts';
import { makeRRuleString } from 'bringg-web/services/rrule/rrule-service';
import { templateSchedulesService } from 'bringg-web/features/delivery-blocks-v2/services/template-schedules-service';
import TimezoneService from 'bringg-web/services/timezone/timezone-service';

import styles from './delivery-block-template.module.scss';

export type DeliveryBlockTemplateUpdate = {
	deliveryBlockTemplate: DeliveryBlockTemplateType;
	originalDeliveryBlockTemplate: DeliveryBlockTemplateType;
};

interface Props {
	onCreateTemplate: (template: DeliveryBlockTemplateType) => Promise<boolean>;
	onEditTemplate: (
		scheduleIdsToRemove: number[],
		schedulesToAdd: Partial<DeliveryBlockTemplateSchedule>[],
		schedulesToUpdate: Partial<DeliveryBlockTemplateSchedule>[],
		update?: DeliveryBlockTemplateUpdate
	) => Promise<boolean>;
	onDiscardChanges: () => void;
	teamsMap: Map<number, Team>;
	backToTemplatesPage: () => void;
	template?: DeliveryBlockTemplateType;
}

const DeliveryBlockTemplate = ({
	onCreateTemplate,
	onEditTemplate,
	onDiscardChanges,
	backToTemplatesPage,
	template: templateProp,
	teamsMap
}: Props) => {
	const { merchantConfigurationsStore } = useStores();
	const { allowMultiDayOptimization, endOfWeekDay, initialLoadingTimeMinutes, initialUnloadingTimeMinutes } =
		merchantConfigurationsStore;
	const use12Hours = dateUtils.use12Hours(merchantConfigurationsStore.hourFormat);
	const format = dateUtils.getMerchantTimeFormat(merchantConfigurationsStore.hourFormat);
	const { t } = useTranslation();
	const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
	const [scheduleIdsToRemove, setScheduleIdsToRemove] = useState<number[]>([]);
	const [schedulesToAdd, setSchedulesToAdd] = useState<Partial<DeliveryBlockTemplateSchedule>[]>([]);
	const [schedulesToUpdate, setSchedulesToUpdate] = useState<Partial<DeliveryBlockTemplateSchedule>[]>([]);
	const [templateSchedules, setTemplateSchedules] = useState<Partial<DeliveryBlockTemplateSchedule>[]>([]);
	const rechargeDuration = initialLoadingTimeMinutes + initialUnloadingTimeMinutes || 0;
	const enableRecharge = useHasFeatureFlag('enable_reload');
	const isRechargeEnabled = enableRecharge && rechargeDuration > 0;
	const isInEditMode = !!templateProp;
	const timezone = 'UTC';

	useEffect(() => {
		setDeliveryBlockTemplate(getDeliveryBlockTemplateCopy(templateProp, true));
	}, [templateProp]);

	const getDeliveryBlockTemplateCopy = (
		deliveryBlockTemplate: DeliveryBlockTemplateType,
		shouldUpdateSchedules: boolean
	) => {
		const defaultStartOfBlock = moment().tz(timezone).startOf('day').add('8', 'hours');
		const template = deliveryBlockTemplate
			? JSON.parse(JSON.stringify(deliveryBlockTemplate))
			: ({} as DeliveryBlockTemplateType);
		template.start_time = template.start_time || defaultStartOfBlock.toISOString();
		template.end_time =
			template.end_time ||
			defaultStartOfBlock.clone().add(DEFAULT_DIFFERENCE_BETWEEN_DATES_IN_HOURS, 'hours').toISOString();
		template.ical = template.ical || makeRRuleString({ freq: Frequency.DAILY });
		template.template_breaks = template.template_breaks ?? [];
		template.template_recharges = template.template_recharges ?? [];
		if (shouldUpdateSchedules && template.template_schedules) {
			const utcTemplateSchedules = convertTimezoneTimesToUTC(template.template_schedules, template.ical);
			setTemplateSchedules(utcTemplateSchedules);
		}

		return template;
	};

	const convertTimezoneTimesToUTC = (
		templatePropSchedules: Partial<DeliveryBlockTemplateSchedule>[],
		ical: string
	) => {
		return templatePropSchedules.map(schedule => {
			const team = teamsMap.get(schedule.team_id);

			return {
				...schedule,
				ical,
				start_time: TimezoneService.getTimezoneDateAsUTC(schedule.start_time, team.time_zone || timezone),
				end_time: TimezoneService.getTimezoneDateAsUTC(schedule.end_time, team.time_zone || timezone),
				termination_time: schedule.termination_time
					? TimezoneService.getTimezoneDateAsUTC(schedule.termination_time, team.time_zone || timezone)
					: undefined
			};
		});
	};

	const [deliveryBlockTemplate, setDeliveryBlockTemplate] = useState<DeliveryBlockTemplateType>(() => {
		return getDeliveryBlockTemplateCopy(templateProp, true);
	});

	const isTemplateValid = () =>
		templateValidator.validate(deliveryBlockTemplate, timezone, allowMultiDayOptimization, rechargeDuration);

	const updateTemplate = <T,>(key: string, value: T) => {
		setDeliveryBlockTemplate(prev => ({ ...prev, [key]: value }));
	};

	const { onIcalChange, onChangeName, onChangeDescription } = deliveryBlockBaseEditor.init(
		deliveryBlockTemplate,
		updateTemplate
	);
	const { onAddBreak, onDeleteBreak, onUpdateBreak, getUpdatedBreaksDates } = breakEditor.init(
		deliveryBlockTemplate,
		updateTemplate
	);
	const { onAddRecharge, onRechargesChange } = rechargeEditor.init(deliveryBlockTemplate, updateTemplate);

	const updateTemplateTimes = (startTime: string, endTime: string) => {
		setDeliveryBlockTemplate(prev => {
			return {
				...prev,
				start_time: startTime,
				end_time: endTime,
				template_breaks: getUpdatedBreaksDates(startTime, timezone),
				template_schedules: getUpdatedSchedulesDates(templateSchedules, startTime, endTime, teamsMap)
			};
		});
	};

	const { getUpdatedSchedulesDates } = templateSchedulesService;

	const onScheduleToRemoveChanged = (schedulesToRemove: number[]) => {
		setScheduleIdsToRemove(oldState => oldState.concat(schedulesToRemove));
	};

	const save = async () => {
		let success: boolean;
		setSaveButtonLoading(true);
		deliveryBlockTemplate.template_schedules = templateSchedules;
		if (isInEditMode) {
			success = await onEditTemplate(scheduleIdsToRemove, schedulesToAdd, schedulesToUpdate, {
				deliveryBlockTemplate: deliveryBlockTemplate,
				originalDeliveryBlockTemplate: getDeliveryBlockTemplateCopy(templateProp, false)
			});
		} else {
			success = await onCreateTemplate(deliveryBlockTemplate);
		}
		setSaveButtonLoading(false);
		if (success) {
			backToTemplatesPage();
		}
	};

	const shouldBeDisabled = isInEditMode && !!templateProp.template_schedules?.length;

	const isSaveDisabled = (): boolean => {
		return (
			(!schedulesToAdd.length && !scheduleIdsToRemove.length && !schedulesToUpdate.length) || !isTemplateValid()
		);
	};

	return (
		<div className={styles.deliveryBlockTemplateMainContainer} data-test-id="delivery-block-template">
			<main className={styles.deliveryBlockTemplateMain}>
				<DeliveryBlockTemplateTitles
					name={deliveryBlockTemplate.name}
					description={deliveryBlockTemplate.description}
					onChangeName={onChangeName}
					onChangeDescription={onChangeDescription}
					disabled={shouldBeDisabled}
				/>
				<DeliveryBlockTime
					className={styles.deliveryBlockTemplateTime}
					deliveryBlockStartTime={deliveryBlockTemplate.start_time}
					deliveryBlockEndTime={deliveryBlockTemplate.end_time}
					updateDeliveryBlockTimes={updateTemplateTimes}
					timezone={timezone}
					use12Hours={use12Hours}
					format={format}
					allowMultiDayOptimization={allowMultiDayOptimization}
					hideDate
					shouldBeDisabled={shouldBeDisabled}
					isTemplate
				/>

				<Collapse
					bordered={false}
					className="delivery-block-collapse"
					expandIcon={({ isActive }) => (
						<div className="collapse-chevron">
							<BringgIcon iconName={isActive ? BringgFontIcons.Chevron : BringgFontIcons.ChevronRight} />
						</div>
					)}
					expandIconPosition="end"
				>
					<RecurrencePanel
						key={PANEL_KEYS.RecurrencePanel}
						ical={deliveryBlockTemplate.ical}
						onIcalChange={onIcalChange}
						timezone={timezone}
						endOfWeekDay={endOfWeekDay}
						deliveryBlockDate={moment(deliveryBlockTemplate.start_time).tz(timezone).date()}
						shouldBeDisabled={shouldBeDisabled}
						hideEndsAt
						recurringFrequencies={allRecurringFrequencies.filter(
							item => item !== RECURRING_NO_REPEAT_FREQUENCY
						)}
					/>
					<BreaksPanel
						key={PANEL_KEYS.BreaksPanel}
						deliveryBlockTimes={{
							startTime: deliveryBlockTemplate.start_time,
							endTime: deliveryBlockTemplate.end_time
						}}
						deliveryBlockBreaks={deliveryBlockTemplate.template_breaks}
						timezone={timezone}
						use12Hours={use12Hours}
						format={format}
						onAddBreak={onAddBreak}
						onDeleteBreak={onDeleteBreak}
						onUpdateBreak={onUpdateBreak}
						allowMultiDayOptimization={allowMultiDayOptimization}
						shouldBeDisabled={shouldBeDisabled}
					/>
					{isRechargeEnabled && (
						<RechargesPanel
							key={PANEL_KEYS.RechargePanel}
							deliveryBlockRecharges={deliveryBlockTemplate.template_recharges ?? []}
							deliveryBlockBreaks={deliveryBlockTemplate.template_breaks ?? []}
							rechargeDuration={rechargeDuration}
							timezone={timezone}
							use12Hours={use12Hours}
							format={format}
							deliveryBlockTimes={{
								startTime: deliveryBlockTemplate.start_time,
								endTime: deliveryBlockTemplate.end_time
							}}
							onChangeRecharge={onRechargesChange}
							onAddRecharge={onAddRecharge}
							shouldBeDisabled={shouldBeDisabled}
						/>
					)}
					<TeamsPanel
						key={PANEL_KEYS.TeamsPanel}
						ical={deliveryBlockTemplate.ical}
						templateSchedules={templateSchedules}
						onTemplateScheduleChanged={setTemplateSchedules}
						timezone={timezone}
						schedulesToAddChanged={setSchedulesToAdd}
						scheduleIdsToRemove={onScheduleToRemoveChanged}
						schedulesToAdd={schedulesToAdd}
						schedulesToUpdate={schedulesToUpdate}
						schedulesToUpdateChanged={setSchedulesToUpdate}
						teamsMap={teamsMap}
						templateStartTime={deliveryBlockTemplate.start_time}
						templateEndTime={deliveryBlockTemplate.end_time}
					/>
				</Collapse>
			</main>
			<footer className={styles.deliveryBlockTemplateFooter}>
				<div className="delivery-block-template-action-container">
					<Button
						className={styles.deliveryBlockTemplateDiscardChangesButton}
						type="default"
						size="large"
						data-test-id="discard-changes-button"
						disabled={false}
						onClick={onDiscardChanges}
					>
						{t('DELIVERY_BLOCKS_TEMPLATE.DISCARD_CHANGES')}
					</Button>
					<Button
						className="delivery-block-template-save-button"
						type="primary"
						loading={saveButtonLoading}
						size="large"
						data-test-id="save-changes-button"
						disabled={isSaveDisabled()}
						onClick={async () => save()}
					>
						{t('DELIVERY_BLOCKS_TEMPLATE.SAVE_CHANGES')}
					</Button>
				</div>
			</footer>
		</div>
	);
};

export default DeliveryBlockTemplate;
