import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import * as Bringg from '@bringg/types';
import { BringgInput, Radio, SelectableList } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import HomeNo from './images/home_y_bt_n.png';
import HomeYes from './images/home_y_bt_y.png';
import VrpAutoDispatchAnalyticsContainer from 'bringg-web/features/vrp-autodispatch-analytics/vrp-auto-dispatch-analytics-container';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { useStores } from 'bringg-web/recipes';
import { executeAction, useCrossApp } from 'bringg-web/services/cross-application/cross-application';
import { EmployeeListInMapTab } from 'bringg-web/services/cross-application/cross-application.actions';
import EmployeesFilter from './employees-filter/employees-filter';
import { EmployeeStateFilter, EmployeeAvailabilityState } from './types';
import { truncateString } from '../../../utils/other';

import styles from './employee-list.module.scss';

export interface RegisterCallbacks {
	setSelectedEmployee(selectedEmployee: Bringg.User | null | undefined): void;

	setDisplayedEmployees(newDisplayed: DisplayedEmployees): void;

	setDriverListFilterByOnShift(fn: () => boolean): void;
	// Must be called after all above functions called
	finish(): void;
}

const VEHICLE_NAME_MAX_LENGTH = 20;

export interface DisplayedEmployees {
	all: Bringg.User[];
	filtered: Bringg.User[];
	onlineCount: number;
	onShiftAndOfflineCount: number;
	onShiftCount: number;
	offShiftCount: number;
}

const DEFAULT_DISPLAYED_EMPLOYEES: DisplayedEmployees = {
	all: [],
	filtered: [],
	onlineCount: 0,
	onShiftAndOfflineCount: 0,
	onShiftCount: 0,
	offShiftCount: 0
};

const EmployeeList = ({ cameFromEmployeeInFocus }: { cameFromEmployeeInFocus?: boolean }) => {
	const { t } = useTranslation();

	const displayVRPADAnalytics = useHasFeatureFlag('display_vrp_auto_dispatch_analytics');
	const allowSearchingEmployeesInMapTab = useHasFeatureFlag('allow_searching_employees_in_map_tab');
	const showDriverRoleInDispatch = useHasFeatureFlag('show_driver_role_in_dispatch');
	const displayBeaconAtHomeIcons = useHasFeatureFlag('display_beacon_at_home_icons');
	const showVehicleName = useHasFeatureFlag('show_vehicle_name_in_employee_list');

	const markHomeEnabled1 = useHasFeatureFlag('markHomeFromMapEnabled');
	const markHomeEnabled2 = useHasFeatureFlag('feature_flag_only_dashboard_home_events');
	const markHomeEnabled = markHomeEnabled1 || markHomeEnabled2;

	const { authStore } = useStores();
	const { crossAppTransport } = authStore;

	const [initFinished, setInitFinished] = useState(false);

	const [driverListFilterByOnShift, setDriverListFilterByOnShift] = useState<() => boolean>(() => () => false);

	const [selectedEmployee, setSelectedEmployee] = useState<Bringg.User | null>(null);
	const [displayedEmployees, setDisplayedEmployees] = useState<DisplayedEmployees>(DEFAULT_DISPLAYED_EMPLOYEES);

	const setDisplayedEmployeesWithFallbackToDefault = useCallback(
		(newDisplayedEmployees: DisplayedEmployees) => {
			setDisplayedEmployees(newDisplayedEmployees || DEFAULT_DISPLAYED_EMPLOYEES);
		},
		[setDisplayedEmployees]
	);

	useCrossApp(
		EmployeeListInMapTab.EMPLOYEE_LIST_EMPLOYEES_UPDATED,
		setDisplayedEmployeesWithFallbackToDefault,
		authStore.crossAppTransport
	);

	useCrossApp(
		EmployeeListInMapTab.EMPLOYEE_LIST_SELECTED_EMPLOYEE_UPDATED,
		setSelectedEmployee,
		authStore.crossAppTransport
	);

	function filterEmployees(employeeNameQuery: string) {
		executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_FILTER_CHANGE, { query: employeeNameQuery });
	}

	function changeEmployeesFilterType(filterType: EmployeeStateFilter) {
		executeAction(crossAppTransport, EmployeeListInMapTab.CHANGE_EMPLOYEE_STATE_FILTER, filterType);
	}

	function changeEmployeesAvailabilityStateFilter(filterType: EmployeeAvailabilityState | undefined) {
		executeAction(crossAppTransport, EmployeeListInMapTab.CHANGE_EMPLOYEE_AVAILABILITY_STATE_FILTER, filterType);
	}

	function driverGotHome(employee: Bringg.User) {
		executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_LIST_DRIVER_GOT_HOME, employee);
	}

	function driverLeftHome(employee: Bringg.User) {
		executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_LIST_DRIVER_LEFT_HOME, employee);
	}

	function pickEmployee(employee: Bringg.User) {
		executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_LIST_PICK_EMPLOYEE, employee);
	}

	useEffect(() => {
		// eslint-disable-next-line prefer-const
		let interval: number;

		const initCbs: RegisterCallbacks = {
			setSelectedEmployee,
			setDisplayedEmployees(newDisplayed: DisplayedEmployees) {
				setDisplayedEmployees(newDisplayed ? newDisplayed : DEFAULT_DISPLAYED_EMPLOYEES);
			},
			setDriverListFilterByOnShift(fn: () => boolean) {
				return setDriverListFilterByOnShift(() => fn);
			},
			// Must be called after all above functions called
			finish() {
				clearInterval(interval);
				setInitFinished(true);
			}
		};

		interval = setInterval(() => {
			executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_LIST_REGISTER, initCbs);
		}, 100) as unknown as number;

		executeAction(crossAppTransport, EmployeeListInMapTab.EMPLOYEE_LIST_REGISTER, initCbs);

		return () => clearInterval(interval);
	}, [crossAppTransport]);

	const employeeStateFilterTypeCounts: Record<EmployeeStateFilter, number> = {
		[EmployeeStateFilter.ALL]: displayedEmployees.all.length,
		[EmployeeStateFilter.ONLINE]: displayedEmployees.onlineCount,
		[EmployeeStateFilter.ON_SHIFT]: displayedEmployees.onShiftCount,
		[EmployeeStateFilter.ON_SHIFT_OFFLINE]: displayedEmployees.onShiftAndOfflineCount,
		[EmployeeStateFilter.OFF_SHIFT]: displayedEmployees.offShiftCount
	};

	if (!initFinished) {
		return <div className={styles.employeeList} id="employee_list"></div>;
	}

	return (
		<div className={styles.employeeList} id="employee_list">
			{displayVRPADAnalytics ? <VrpAutoDispatchAnalyticsContainer /> : null}

			<div className={styles.employeesHeader}>
				{allowSearchingEmployeesInMapTab ? (
					<BringgInput
						placeholder={t('GLOBAL.SEARCH')}
						className={styles.searchDrivers}
						onChange={e => filterEmployees(e.target.value)}
						prefix={<BringgIcon className="search-icon" iconName={BringgFontIcons.Search} />}
					/>
				) : null}
				<EmployeesFilter
					cameFromEmployeeInFocus={cameFromEmployeeInFocus}
					onFilterUpdate={({ employeeState, tasksState }) => {
						changeEmployeesFilterType(employeeState);
						changeEmployeesAvailabilityStateFilter(tasksState);
					}}
					employeeStateFilterTypeCounts={employeeStateFilterTypeCounts}
				/>
			</div>

			{displayedEmployees.filtered.length ? (
				<SelectableList
					itemClassName={styles.employeeListItemWrapper}
					labelKey="name"
					renderValue={employee => (
						<div
							data-test-id={`employee-item-${employee.id}`}
							key={employee.id}
							className={classnames(styles.listItem, {
								[styles.selected]: employee.id === selectedEmployee?.id
							})}
						>
							<div
								role="button"
								className={classnames(styles.employeeNameAndProfile, {
									[styles.withMargin]: !showVehicleName
								})}
								onClick={() => pickEmployee(employee)}
							>
								<div className={styles.employeeDetailsWrapper}>
									<img
										src={employee.profile_image}
										alt="Profile picture"
										className={classnames(styles.avatar)}
									/>
									<a className={styles.employeeName}>{employee.name}</a>
								</div>
							</div>

							{employee.current_unavailability_id ? (
								<span className={styles.employeeExtraData}>{t('DISPATCH.DRIVER_UNAVAILABLE')}</span>
							) : null}

							{employee.is_on_break && !employee.current_unavailability_id ? (
								<span className={styles.employeeExtraData}>
									{t('MAP_USER_PROFILE.DRIVER_ON_BREAK')}
								</span>
							) : null}

							{showDriverRoleInDispatch && !employee.is_on_break ? (
								<span className={styles.employeeExtraData}>{employee.userType?.title}</span>
							) : null}

							{showVehicleName && (
								<span className={styles.employeeVehicle}>
									{truncateString(employee?.vehicle?.name, VEHICLE_NAME_MAX_LENGTH)}
								</span>
							)}

							<div className={styles.employeeIconsBackground}>
								{displayBeaconAtHomeIcons && driverListFilterByOnShift() ? (
									<span className={styles.employeeBeaconHomeIconsWrapper}>
										<img
											src={HomeNo}
											className={classnames(styles.beaconHomeIcon, {
												[styles.hide]: !(employee.at_home && !employee.in_beacon_range)
											})}
										/>
										<img
											src={HomeYes}
											className={classnames(styles.beaconHomeIcon, {
												[styles.hide]: !(employee.at_home && employee.in_beacon_range)
											})}
										/>
									</span>
								) : null}

								{markHomeEnabled && driverListFilterByOnShift() ? (
									<Radio.Group
										disabled={employee.sending_home_event}
										value={employee.at_home ? 'home-in' : 'home-out'}
									>
										<Radio
											data-test-id={`employee-${employee.id}-in-radio`}
											type="radio"
											value="home-in"
											onChange={() => driverGotHome(employee)}
										>
											{t('MAP.HOME_IN')}
										</Radio>
										<Radio
											type="radio"
											data-test-id={`employee-${employee.id}-out-radio`}
											value="home-out"
											onChange={() => driverLeftHome(employee)}
										>
											{t('MAP.HOME_OUT')}
										</Radio>
									</Radio.Group>
								) : null}
							</div>
						</div>
					)}
					items={displayedEmployees.filtered}
					// required
					onSelect={() => undefined}
				/>
			) : null}
		</div>
	);
};

export default withErrorBoundary(observer(EmployeeList));
