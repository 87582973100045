import { useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import WorkflowStore from '../stores/workflow-store';
import { AutomationsTab } from '../utils/types';

export enum WorkflowFromState {
	WORKFLOW
}

export type WorkflowNavigationState = {
	backTab?: AutomationsTab;
	workflow?: WorkflowStore;
};

const qsOptions: qs.IStringifyOptions<qs.BooleanOptional> = {
	encode: true,
	indices: false,
	format: 'RFC1738'
};

const removeTrailingSlash = (path: string) => (path?.endsWith('/') ? path.slice(0, -1) : path);

export const FOCUSED_LEGACY_ALERT_PARAM = 'focused-alert';
export const AUTOMATION_PATH = '/merchant/automations';

const useWorkflowNavigation = () => {
	const location = useLocation();
	const history = useHistory();

	const pathWithoutTrailingSlash = removeTrailingSlash(location.pathname);

	const goToNewWorkflow = useCallback(
		(state?: WorkflowNavigationState) => {
			history.push({ pathname: `${pathWithoutTrailingSlash}/new`, state });
		},
		[history, pathWithoutTrailingSlash]
	);

	const goToExistingWorkflow = useCallback(
		(workflowId: number, state?: WorkflowNavigationState) => {
			history.push({ pathname: `${pathWithoutTrailingSlash}/${workflowId}`, state });
		},
		[history, pathWithoutTrailingSlash]
	);

	const goToAutomationBoard = useCallback(
		(filter?) => {
			history.push({
				pathname: AUTOMATION_PATH,
				search: filter && qs.stringify(filter, qsOptions)
			});
		},
		[history]
	);

	const goToTemplatesTab = useCallback(() => {
		history.push({
			pathname: AUTOMATION_PATH,
			search: qs.stringify(
				{
					tab: AutomationsTab.TEMPLATES
				},
				qsOptions
			)
		});
	}, [history, pathWithoutTrailingSlash]);

	const focusOnSpecificLegacyAlert = useCallback(
		(alertId: number) => {
			history.push({
				pathname: pathWithoutTrailingSlash,
				search: qs.stringify(
					{
						tab: AutomationsTab.SYSTEM_WORKFLOWS,
						[FOCUSED_LEGACY_ALERT_PARAM]: alertId
					},
					qsOptions
				)
			});
		},
		[history, pathWithoutTrailingSlash]
	);

	return {
		goToNewWorkflow,
		goToExistingWorkflow,
		goToAutomationBoard,
		goToTemplatesTab,
		focusOnSpecificLegacyAlert
	};
};

export default useWorkflowNavigation;
