import { default as parsePhoneNumber, type MetadataJson, type CountryCode, Metadata } from 'libphonenumber-js/core';

import { chooseFormatForNumber } from '../utils';
import customMetadata from '../../../../metadata/libphonenumber-js.metadata.json';
import { type FixedMetadata } from '../types';

type Options = {
	countryCode?: string;
	format?: 'INTERNATIONAL' | 'NATIONAL';
};

export class PhoneNumberService {
	private readonly metadata: FixedMetadata;

	constructor() {
		this.metadata = new Metadata(customMetadata as MetadataJson) as FixedMetadata;
	}

	formatPhoneNumberDefault = (phone: string, options?: Options): string => {
		if (!phone) {
			return phone;
		}

		const { format = 'INTERNATIONAL', countryCode } = options || { format: 'INTERNATIONAL' };
		const parsed = parsePhoneNumber(phone, countryCode as CountryCode, customMetadata as MetadataJson);

		if (!parsed || !parsed.isValid()) {
			return phone;
		}

		return format === 'NATIONAL' ? parsed.formatNational() : parsed.formatInternational();
	};

	formatPhoneNumberOriginal = (phone: string, options?: Options): string => {
		if (!phone) {
			return phone;
		}

		const { format = 'INTERNATIONAL', countryCode } = options || { format: 'INTERNATIONAL' };
		const parsed = parsePhoneNumber(phone, countryCode as CountryCode, customMetadata as MetadataJson);

		if (!parsed || !parsed.isValid()) {
			return phone;
		}

		this.metadata.selectNumberingPlan(parsed.countryCallingCode as CountryCode);
		const metadataFormat = chooseFormatForNumber(this.metadata.formats(), parsed.nationalNumber);
		const isInternationalFormat = format === 'INTERNATIONAL';

		const nationalPhoneNumber = parsed.nationalNumber.replace(
			new RegExp(metadataFormat.pattern()),
			isInternationalFormat ? metadataFormat.internationalFormat() : metadataFormat.format()
		);

		return isInternationalFormat ? `+${parsed.countryCallingCode} ${nationalPhoneNumber}` : nationalPhoneNumber;
	};

	formatPhoneNumber = (phone: string, options?: Options): string => {
		return this.formatPhoneNumberOriginal(phone, options);
	};
}

export const phoneNumberService = new PhoneNumberService();
