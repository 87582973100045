import React, { useMemo } from 'react';

import { BringgTable, Button, DropdownMenu, Spinner } from '@bringg/react-components';
import { CellProps, Column, useFlexLayout, useTable } from 'react-table';
import moment from 'moment-timezone';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ExclusionWindow, PrivilegeTypes } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useHasAccess } from 'bringg-web/utils/privileges';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import { useHasFeatureFlag } from 'bringg-web';

import styles from './exclusion-window-table.module.scss';

interface Props {
	format: string;
	selectedTeamId: number;
	exclusionWindows: ExclusionWindow[];
	timezone: string;
	openExclusionWindowModalWithEditMode: (id: number) => void;
	onDeleteOpenConfirmModal: (id: number) => void;
	tableLoading: boolean;
}

const ExclusionWindowTable = ({
	format,
	exclusionWindows,
	timezone,
	openExclusionWindowModalWithEditMode,
	onDeleteOpenConfirmModal,
	tableLoading,
	selectedTeamId
}: Props) => {
	const { t } = useTranslation();
	const useSlotsAndBlackoutsPrivilegesFeatureFlag = useHasFeatureFlag('use_slots_and_blackouts_privileges');
	const hasAccessToEditByPrivilege = useHasAccess(PrivilegeTypes.EDIT_EXCLUSION_WINDOWS);
	const isEditAllowed = useSlotsAndBlackoutsPrivilegesFeatureFlag ? hasAccessToEditByPrivilege : true;

	const data = useMemo(() => {
		return exclusionWindows;
	}, [exclusionWindows]);

	const columns: Column<Partial<ExclusionWindow>>[] = useMemo(() => {
		const items: Column<Partial<ExclusionWindow>>[] = [
			{
				Header: t<string>('EXCLUSION_WINDOWS_TABLE_HEADER.EXCLUSION_NAME'),
				accessor: 'name',
				Cell: ({ value }) => <span data-test-id="exclusion-table-name">{value}</span>
			},
			{
				Header: t<string>('EXCLUSION_WINDOWS_TABLE_HEADER.EFFECTIVE_DATES'),
				Cell: ({ row }: CellProps<ExclusionWindow>) => {
					return (
						<span data-test-id="exclusion-table-effective-dates">{`${moment(row.original.start_time)
							.tz(timezone)
							.format(format)} - ${moment(row.original.end_time).tz(timezone).format(format)}`}</span>
					);
				}
			},
			{
				Header: t<string>('EXCLUSION_WINDOWS_TABLE_HEADER.ORIGIN'),
				accessor: 'team_id',
				Cell: ({ value }) => {
					return (
						<span data-test-id="exclusion-table-origin">
							{value !== null
								? t('EXCLUSION_WINDOWS_TABLE.TEAM_TEXT')
								: t('EXCLUSION_WINDOWS_TABLE.MERCHANT_TEXT')}
						</span>
					);
				}
			}
		];

		if (isEditAllowed) {
			items.push({
				Header: t<string>('EXCLUSION_WINDOWS_TABLE_HEADER.ACTIONS'),
				Cell: ({ row }: CellProps<ExclusionWindow>) => {
					const onClickEdit = () => openExclusionWindowModalWithEditMode(row.original.id);
					const onClickDelete = () => onDeleteOpenConfirmModal(row.original.id);
					const hasAccessToEditByOrigin =
						selectedTeamId === NO_TEAM ? !row.original.team_id : row.original.team_id;

					return (
						<DropdownMenu
							items={[
								{
									key: '1',
									label: (
										<span data-test-id="exclusion-table-actions-edit">
											{t('EXCLUSION_WINDOWS_VIEW_BUTTON_DROPDOWN.EDIT_TEXT')}
										</span>
									),
									onClick: onClickEdit,
									disabled: !hasAccessToEditByPrivilege || !hasAccessToEditByOrigin
								},
								{
									key: '2',
									label: (
										<span data-test-id="exclusion-table-actions-delete">
											{t('EXCLUSION_WINDOWS_VIEW_BUTTON_DROPDOWN.DELETE_TEXT')}
										</span>
									),
									onClick: onClickDelete,
									disabled: !hasAccessToEditByPrivilege || !hasAccessToEditByOrigin
								}
							]}
							trigger={['click']}
						>
							<Button
								type="text"
								disabled={!hasAccessToEditByPrivilege}
								icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />}
								data-test-id="exclusion-table-actions"
							></Button>
						</DropdownMenu>
					);
				}
			});
		}

		return items;
	}, [exclusionWindows, selectedTeamId]);

	const tableInstance = useTable(
		{
			columns,
			data
		},
		useFlexLayout
	);

	return (
		<div data-test-id={'exclusion-windows-table-container'}>
			{tableLoading && <Spinner className={styles.spinner} />}
			<BringgTable
				tableInstance={tableInstance}
				className={classNames({ [styles.disabledMode]: tableLoading })}
				maxHeight={'80vh'}
			/>
		</div>
	);
};
export default ExclusionWindowTable;
