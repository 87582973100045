import React, { useMemo, useState } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { BringgTable, Button, DatePicker, InputNumber, TreeSelect } from '@bringg/react-components';
import { Column, Row, useExpanded, useFlexLayout, useGroupBy, useTable } from 'react-table';
import { DeliveryBlockTemplateSchedule, Team as TeamType } from '@bringg/types';
import { endOfDay, getStartOfDayMoment } from '@bringg-frontend/utils';
import { groupBy as _groupBy } from 'lodash';
import { RRule } from 'rrule';
import moment from 'moment-timezone';

import useDateFormat from 'bringg-web/hooks/use-date-format';
import { useTeamsTreeData } from 'bringg-web/hooks';
import { makeRRuleString } from 'bringg-web/services/rrule/rrule-service';
import Team from 'bringg-web/stores/teams/domain-object/team';
import { templateSchedulesService } from 'bringg-web/features/delivery-blocks-v2/services/template-schedules-service';

import styles from '../teams-panel/teams-panel.module.scss';

interface Props {
	ical: string;
	templateSchedules: Partial<DeliveryBlockTemplateSchedule>[];
	teams: Team[];
	timezone: string;
	teamsMap: Map<number, TeamType>;
	onEditTeams: (teamIds: number[], startTime: string, endTime: string, capacity: number) => void;
	onTemplateScheduleChanged: (templateSchedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	scheduleIdsToRemove: (scheduleIds: number[]) => void;
	schedulesToAddChanged: (schedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	schedulesToUpdateChanged: (schedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	schedulesToAdd: Partial<DeliveryBlockTemplateSchedule>[];
	schedulesToUpdate: Partial<DeliveryBlockTemplateSchedule>[];
	templateStartTime: string;
	templateEndTime: string;
}

enum ColumnValuesTypes {
	START_TIME = 'start_time',
	END_TIME = 'end_time',
	ORIGINAL_CAPACITY = 'original_capacity'
}

const getUpdatedTemplateTime = (newDate: string, templateTime: string, format: string): string => {
	const updatedNewDate = moment(newDate, format);
	const time = moment(templateTime);
	return updatedNewDate.set({ h: time.hour(), m: time.minute(), s: time.second() }).toISOString();
};

const TeamsTable = ({
	templateSchedules,
	ical,
	teams,
	timezone,
	onEditTeams,
	onTemplateScheduleChanged,
	scheduleIdsToRemove,
	schedulesToAddChanged,
	schedulesToAdd,
	schedulesToUpdateChanged,
	schedulesToUpdate,
	templateStartTime,
	templateEndTime,
	teamsMap
}: Props) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();
	const teamOptions = useTeamsTreeData(teams);
	const [initialTemplateSchedules] = useState<Partial<DeliveryBlockTemplateSchedule>[]>(
		JSON.parse(JSON.stringify(templateSchedules))
	);

	const deleteSchedule = (schedule: DeliveryBlockTemplateSchedule) => {
		const schedulesIdsToRemove: number[] = [];
		const filteredSchedules = [];

		templateSchedules.forEach(templateSchedule => {
			if (isScheduleSame(templateSchedule, schedule)) {
				const updatedAddedSchedules = schedulesToAdd.filter(
					scheduleToAdd => scheduleToAdd !== templateSchedule
				);
				schedulesToAddChanged(updatedAddedSchedules);

				const updatedSchedulesToUpdate = schedulesToUpdate.filter(
					scheduleToUpdate => scheduleToUpdate.id !== templateSchedule.id
				);
				schedulesToUpdateChanged(updatedSchedulesToUpdate);

				if (templateSchedule.id) {
					schedulesIdsToRemove.push(templateSchedule.id);
				}
			} else {
				filteredSchedules.push(templateSchedule);
			}
		});
		onTemplateScheduleChanged(filteredSchedules);
		scheduleIdsToRemove(schedulesIdsToRemove);
	};

	const areSchedulesTeamIdEqual = (templateSchedule, schedule) => {
		return schedule.team_id === templateSchedule.team_id || schedule.team_ids?.includes(templateSchedule.team_id);
	};

	const areSchedulesStartTimeEqual = (templateSchedule, schedule) => {
		return (
			schedule.start_time === templateSchedule.start_time || schedule.start_date === templateSchedule.start_time
		);
	};

	const areSchedulesOriginalCapacityEqual = (templateSchedule, schedule) => {
		return (
			Number(schedule.capacity) === templateSchedule.original_capacity ||
			schedule.original_capacity === templateSchedule.original_capacity
		);
	};

	const areSchedulesTerminationTimeEqual = (templateSchedule, schedule) => {
		return (
			schedule.termination_time === templateSchedule.termination_time ||
			schedule.end_date === templateSchedule.termination_time
		);
	};

	const isScheduleSame = (templateSchedule, schedule) => {
		return (
			areSchedulesTeamIdEqual(templateSchedule, schedule) &&
			areSchedulesStartTimeEqual(templateSchedule, schedule) &&
			areSchedulesTerminationTimeEqual(templateSchedule, schedule) &&
			areSchedulesOriginalCapacityEqual(templateSchedule, schedule)
		);
	};

	const getGroupedSchedules = () => {
		return _groupBy(templateSchedules, item => {
			const startTime = item.start_time;
			const endTime = item.termination_time;
			return [startTime, endTime, item.original_capacity].join(',');
		});
	};

	const updatedSchedules = useMemo(() => {
		const newSchedules = [];

		if (!templateSchedules.length) {
			return newSchedules;
		}

		const groupedSchedules = getGroupedSchedules();

		Object.keys(groupedSchedules).forEach(key => {
			const data = key.split(',');
			const [startTime, endTime, originalCapacity] = data;
			const value = groupedSchedules[key];
			let options = RRule.fromString(ical).origOptions;

			if (endTime) {
				options = { ...options, until: moment(endTime).tz(timezone).toDate() };
			}
			const updatedIcal = makeRRuleString(options);
			const filteredTeamIds = value.map(schedule => schedule.team_id);

			if (filteredTeamIds.length) {
				newSchedules.push({
					start_time: startTime,
					end_time: endTime,
					original_capacity: originalCapacity,
					team_ids: filteredTeamIds,
					ical: updatedIcal
				});
			}
		});
		return newSchedules;
	}, [templateSchedules]);

	const onScheduleTeamIdsChanged = (
		row: Row<Partial<DeliveryBlockTemplateSchedule>>,
		newTeamIds: number[],
		teamIds: number[]
	) => {
		const { start_date, end_date, capacity } = row.values;

		if (teamIds.length > newTeamIds.length) {
			const scheduleIds: number[] = [];
			const currentTemplateSchedules = [...templateSchedules];
			const teamIdToRemove = teamIds.find(item => newTeamIds.indexOf(item) < 0);
			const scheduleIndex = templateSchedules.findIndex(
				templateSchedule => templateSchedule.team_id === teamIdToRemove
			);
			const updatedAddedSchedule = schedulesToAdd.filter(
				schedule => schedule !== templateSchedules[scheduleIndex]
			);
			schedulesToAddChanged(updatedAddedSchedule);

			const scheduleId = templateSchedules[scheduleIndex].id;
			scheduleId && scheduleIds.push(scheduleId);
			scheduleIdsToRemove(scheduleIds);

			currentTemplateSchedules.splice(scheduleIndex, 1);
			onTemplateScheduleChanged(currentTemplateSchedules);
		} else {
			const teamIdsToAdd = newTeamIds.filter(teamId => !teamIds.includes(teamId));
			onEditTeams(teamIdsToAdd, start_date, end_date, capacity);
		}
	};

	const onTeamsTemplateValueUpdated = (
		row: Row<Partial<DeliveryBlockTemplateSchedule>>,
		newValue: string | number,
		column: ColumnValuesTypes
	) => {
		const currentTemplateSchedules = [...templateSchedules];
		const { team_ids, start_date, end_date, capacity } = row.values;

		team_ids.forEach((teamId: number) => {
			const scheduleByTeamId: Partial<DeliveryBlockTemplateSchedule> = currentTemplateSchedules.find(
				scheduleItem =>
					scheduleItem.team_id === teamId &&
					scheduleItem.start_time === start_date &&
					scheduleItem.termination_time === end_date &&
					scheduleItem.original_capacity === Number(capacity)
			);

			if (!scheduleByTeamId) {
				return;
			}

			switch (column) {
				case ColumnValuesTypes.START_TIME: {
					const updatedStartTime = getUpdatedTemplateTime(newValue as string, templateStartTime, dateFormat);
					scheduleByTeamId.start_time = updatedStartTime;
					scheduleByTeamId.end_time = updatedStartTime;
					if (moment(scheduleByTeamId.termination_time).isBefore(updatedStartTime)) {
						scheduleByTeamId.termination_time = updatedStartTime;
					}
					break;
				}

				case ColumnValuesTypes.END_TIME: {
					scheduleByTeamId.termination_time = getUpdatedTemplateTime(
						newValue as string,
						templateEndTime,
						dateFormat
					);
					break;
				}

				case ColumnValuesTypes.ORIGINAL_CAPACITY: {
					scheduleByTeamId.original_capacity =
						!newValue || !Number.isInteger(+newValue) || +newValue < 1 ? 1 : +newValue;
				}
			}

			scheduleByTeamId.ical = makeRRuleString({
				...RRule.fromString(ical).origOptions,
				until: moment(scheduleByTeamId.termination_time).toDate()
			});

			if (!scheduleByTeamId.id) {
				return;
			}

			const item = schedulesToUpdate.find(schedule => schedule.id === scheduleByTeamId.id);

			if (item) {
				item.original_capacity = scheduleByTeamId.original_capacity;
				item.ical = scheduleByTeamId.ical;
				item.start_time = scheduleByTeamId.start_time;
				item.end_time = scheduleByTeamId.end_time;
				item.termination_time = scheduleByTeamId.termination_time;
			} else {
				schedulesToUpdate.push({
					id: scheduleByTeamId.id,
					original_capacity: scheduleByTeamId.original_capacity,
					ical: scheduleByTeamId.ical,
					start_time: scheduleByTeamId.start_time,
					end_time: scheduleByTeamId.end_time,
					termination_time: scheduleByTeamId.termination_time,
					team_id: teamId
				});
			}
		});

		updateAndNotifyChangedSchedules(currentTemplateSchedules);
	};

	const updateAndNotifyChangedSchedules = (currentTemplateSchedules: Partial<DeliveryBlockTemplateSchedule>[]) => {
		const schedulesToUpdateDates = templateSchedulesService.getUpdatedSchedulesDates(
			schedulesToUpdate,
			templateStartTime,
			templateEndTime,
			teamsMap
		);

		const filteredSchedulesToUpdate = schedulesToUpdateDates.filter(schedule => {
			const foundSchedule = initialTemplateSchedules.find(
				templateSchedule => templateSchedule.id === schedule.id
			);
			return !!foundSchedule && !isScheduleSame(foundSchedule, schedule);
		});

		schedulesToUpdateChanged(filteredSchedulesToUpdate);

		const updatedTemplateSchedulesDates = templateSchedulesService.getUpdatedSchedulesDates(
			currentTemplateSchedules,
			templateStartTime,
			templateEndTime,
			teamsMap
		);

		onTemplateScheduleChanged(updatedTemplateSchedulesDates);
	};

	const columns: Column<Partial<DeliveryBlockTemplateSchedule>>[] = useMemo(
		() =>
			[
				{
					id: 'team_ids',
					accessor: 'team_ids',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.TEAMS_TITLE',
					Cell: ({ row, value }) => {
						return (
							<TreeSelect
								id={`teams-field-${row.id}`}
								className={styles.teamsSelect}
								selectAllThreshold={1}
								data-test-id="teams-field"
								allowSelectAll
								showArrow
								selectAllText={t('MULTI_SELECT.SELECT_ALL')}
								placeholder={t('DELIVERY_BLOCK_TEMPLATE.TEAMS.SELECT_TEAMS')}
								treeData={teamOptions}
								selectAllTagText={t('DELIVERY_BLOCK_TEMPLATE.TEAMS.ALL_TEAMS')}
								value={value}
								onChange={teamIdsValue =>
									onScheduleTeamIdsChanged(row, teamIdsValue as number[], value)
								}
							/>
						);
					}
				},
				{
					id: 'grouped_capacity',
					accessor: 'original_capacity',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.CAPACITY_TITLE',
					Cell: ({ value }) => <span data-test-id="delivery-block-template-capacity">{value}</span>
				},
				{
					id: 'capacity',
					accessor: 'original_capacity',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.CAPACITY_TITLE',
					Cell: ({ row }) => {
						if (!row.canExpand) {
							return (
								<InputNumber
									data-test-id="delivery-block-template-capacity"
									value={row.values.capacity}
									min={1}
									onBlur={e =>
										onTeamsTemplateValueUpdated(
											row,
											e.target.value,
											ColumnValuesTypes.ORIGINAL_CAPACITY
										)
									}
									controls={false}
								/>
							);
						}
						return null;
					}
				},
				{
					id: 'grouped_start_date',
					accessor: 'start_time',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.START_DATE_TITLE',
					Cell: ({ value }) => (
						<span data-test-id="delivery-block-template-start-date">
							{getStartOfDayMoment(value, timezone).format(dateFormat)}
						</span>
					)
				},
				{
					id: 'grouped_end_date',
					accessor: 'end_time',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.END_DATE_TITLE',
					Cell: ({ value }) => (
						<span data-test-id="delivery-block-template-end-date">
							{endOfDay(value, timezone).format(dateFormat)}
						</span>
					)
				},
				{
					id: 'start_date',
					accessor: 'start_time',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.START_DATE_TITLE',
					Cell: ({ row }) => {
						if (!row.canExpand) {
							return (
								<DatePicker
									className={styles.schedulesStartDatePicker}
									data-test-id={'delivery-block-template-start-date-picker'}
									defaultValue={getStartOfDayMoment(row.values.start_date, timezone)}
									format={dateFormat}
									suffixIcon={
										<BringgIcon
											className={styles.iconEditStartDate}
											iconName={BringgFontIcons.Pencil}
										/>
									}
									allowClear={false}
									bordered={false}
									onChange={(oldDate, newDate) =>
										onTeamsTemplateValueUpdated(row, newDate, ColumnValuesTypes.START_TIME)
									}
								/>
							);
						}
						return null;
					}
				},
				{
					id: 'end_date',
					accessor: 'end_time',
					translateKey: 'DELIVERY_BLOCK_TEMPLATE.END_DATE_TITLE',
					Cell: ({ row }) => {
						if (!row.canExpand) {
							return (
								<DatePicker
									className={styles.schedulesEndDatePicker}
									data-test-id={'delivery-block-template-end-date-picker'}
									defaultValue={
										row.values.end_date ? endOfDay(row.values.end_date, timezone) : undefined
									}
									format={dateFormat}
									suffixIcon={
										<BringgIcon
											className={styles.iconEditEndDate}
											iconName={BringgFontIcons.Pencil}
										/>
									}
									allowClear={false}
									bordered={false}
									onChange={(oldDate, newDate) =>
										onTeamsTemplateValueUpdated(row, newDate, ColumnValuesTypes.END_TIME)
									}
									disabledDate={currentDate => {
										return currentDate.isBefore(
											getStartOfDayMoment(row.values.start_date, timezone)
										);
									}}
								/>
							);
						}
						return null;
					}
				},
				{
					id: 'delete_schedule',
					width: 60,
					Cell: ({ row }) => (
						<Button
							className={styles.iconDeleteContainer}
							type="link"
							onClick={() => {
								deleteSchedule(row.values);
							}}
						>
							<BringgIcon iconName={BringgFontIcons.Trash} />
						</Button>
					)
				}
			].map(col => ({
				...col,
				Header: <span className="delivery-blocks-schedules-title">{t(col.translateKey)}</span>
			})) as Column<Partial<DeliveryBlockTemplateSchedule>>[],
		[templateSchedules, templateStartTime, templateEndTime]
	);

	const tableInstance = useTable<Partial<DeliveryBlockTemplateSchedule>>(
		{
			data: updatedSchedules,
			columns,
			initialState: {
				hiddenColumns: ['grouped_start_date', 'grouped_end_date', 'grouped_capacity']
			}
		},
		useFlexLayout,
		useGroupBy,
		useExpanded
	);

	return <BringgTable className={styles.teamsPanelTable} tableInstance={tableInstance} />;
};

export default TeamsTable;
