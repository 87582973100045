import { useEffect, useRef, useState } from 'react';

import * as turf from '@turf/turf';
import { Feature, LineString } from '@turf/helpers';
import { useHasFeatureFlag } from '@bringg-frontend/hooks';

import { RouteLocation } from '../../../../types';
import { MAIN_STEPS } from '../../../consts';

const useBringgMapRoutePlayerInit = (initialRoute: RouteLocation[]) => {
	const fixTimeLineMapRouterPlayer = useHasFeatureFlag('fix_time_line_map_router_player');

	const [route, setRoute] = useState<RouteLocation[]>([]);
	const routeFeature = useRef<Feature<LineString>>({
		type: 'Feature',
		properties: {},
		geometry: {
			type: 'LineString',
			coordinates: [
				[initialRoute[0]?.lng, initialRoute[0]?.lat],
				[initialRoute[1]?.lng, initialRoute[1]?.lat]
			]
		}
	});

	const initRoute = () => {
		const arc = [];
		for (let i = 0; i < initialRoute.length - 1; i++) {
			routeFeature.current.geometry.coordinates = [
				[initialRoute[i].lng, initialRoute[i].lat],
				[initialRoute[i + 1].lng, initialRoute[i + 1].lat]
			];

			const lineDistance = turf.length(routeFeature.current);
			for (let j = 0; j < lineDistance; j += lineDistance / MAIN_STEPS) {
				const segment = turf.along(routeFeature.current, j);
				arc.push(segment.geometry.coordinates);
			}
		}
		routeFeature.current.geometry.coordinates = arc;
		const newRoute = [];
		for (let k = 0; k < arc.length; k++) {
			let calcInitialRouteIndex = Math.round(k / MAIN_STEPS);
			calcInitialRouteIndex =
				calcInitialRouteIndex >= initialRoute.length ? initialRoute.length - 1 : calcInitialRouteIndex;
			newRoute[k] = {
				lng: arc[k][0],
				lat: arc[k][1],
				happened_at: initialRoute[calcInitialRouteIndex].happened_at
			};
		}
		setRoute(newRoute);
	};
	const newInitRoute = () => {
		const arc = [];
		for (let i = 0; i < initialRoute.length - 1; i++) {
			routeFeature.current.geometry.coordinates = [
				[initialRoute[i].lng, initialRoute[i].lat],
				[initialRoute[i + 1].lng, initialRoute[i + 1].lat]
			];

			const lineDistance = turf.length(routeFeature.current);

			if (!lineDistance) {
				const segment = turf.along(routeFeature.current, 0);
				arc.push({ coordinates: segment.geometry.coordinates, happened_at: initialRoute[i].happened_at });
				arc.push({ coordinates: segment.geometry.coordinates, happened_at: initialRoute[i + 1].happened_at });

				continue;
			}

			const firstDate = new Date(initialRoute[i].happened_at);
			const lastDate = new Date(initialRoute[i + 1].happened_at);
			const timeDiff = lastDate.getTime() - firstDate.getTime();
			const step = timeDiff / MAIN_STEPS;

			for (let j = 0, d = 0; j <= lineDistance; j += lineDistance / MAIN_STEPS, d++) {
				const nextHappenedAt = new Date(firstDate.getTime() + step * d).toISOString();
				const segment = turf.along(routeFeature.current, j);
				arc.push({ coordinates: segment.geometry.coordinates, happened_at: nextHappenedAt });
			}
		}

		const newRoute = arc.map(point => ({
			lng: point.coordinates[0],
			lat: point.coordinates[1],
			happened_at: point.happened_at
		}));

		setRoute(newRoute);
	};

	useEffect(() => {
		if (fixTimeLineMapRouterPlayer) {
			newInitRoute();
		} else {
			initRoute();
		}
	}, [initialRoute]);

	return { route };
};

export default useBringgMapRoutePlayerInit;
